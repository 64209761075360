import React from 'react';
import {
  Button,
  IconButton,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleKeyboardAwareScrollView,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobzChatApi from '../apis/XanoJobzChatApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { ID_chatroom: '' };

const JobzChatChatsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [ID_Chatroom, setID_Chatroom] = React.useState(0);
  const [INPUT_message, setINPUT_message] = React.useState('');
  const [PLACEHOLDER_message, setPLACEHOLDER_message] = React.useState(
    'Press Send When Done'
  );
  const xanoJobzChatJOBZCHATSaveAChatMessagePOST =
    XanoJobzChatApi.useJOBZCHATSaveAChatMessagePOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setID_Chatroom(ID_Chatroom);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
          dimensions.width
        )}
      >
        {/* Header Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App['Primary 4'],
              justifyContent: 'space-around',
            },
            dimensions.width
          )}
        >
          {/* Header */}
          <View
            style={StyleSheet.applyWidth(
              { margin: 20, marginBottom: 20 },
              dimensions.width
            )}
          >
            {/* Description */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['h2'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['h2'].style,
                dimensions.width
              )}
            >
              {'Keep it professional, please.'}
            </Text>
          </View>
        </View>
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          {/* SCREEN CONTAINER */}
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, justifyContent: 'space-between' },
              dimensions.width
            )}
          >
            {/* top nav */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 20,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                },
                dimensions.width
              )}
            >
              {/* Back */}
              <IconButton
                onPress={() => {
                  try {
                    navigation.navigate('JobzChatScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                icon={'AntDesign/arrowleft'}
              />
            </View>
            {/* Chat Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: 1,
                  height: dimensions.height,
                  justifyContent: 'space-between',
                  marginLeft: 20,
                  marginRight: 20,
                },
                dimensions.width
              )}
            >
              <SimpleStyleKeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                keyboardShouldPersistTaps={'never'}
                showsVerticalScrollIndicator={true}
                viewIsInsideTabBar={false}
                style={StyleSheet.applyWidth(
                  { maxHeight: 600 },
                  dimensions.width
                )}
              >
                <XanoJobzChatApi.FetchJOBZCHATGetAllChatsFromASingleChatroomGET
                  chatroomID={
                    props.route?.params?.ID_chatroom ?? defaultProps.ID_chatroom
                  }
                >
                  {({
                    loading,
                    error,
                    data,
                    refetchJOBZCHATGetAllChatsFromASingleChatroom,
                  }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <SimpleStyleFlatList
                        data={fetchData}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ??
                          listData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'XvVNEOel'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <>
                              {/* Chat Area */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flex: 1 },
                                  dimensions.width
                                )}
                              >
                                {/* Other */}
                                <>
                                  {listData?._chat_of_user ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'flex-start',
                                          backgroundColor:
                                            theme.colors.branding.primary,
                                          borderRadius: 50,
                                          flexDirection: 'row',
                                          gap: 10,
                                          marginBottom: 6,
                                          paddingBottom: 4,
                                          paddingLeft: 8,
                                          paddingRight: 8,
                                          paddingTop: 4,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Image Other */}
                                      <Image
                                        resizeMode={'cover'}
                                        {...GlobalStyles.ImageStyles(theme)[
                                          'Image'
                                        ].props}
                                        source={imageSource(
                                          `${listData?._userChats?._image_of_user?.image?.url}`
                                        )}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.ImageStyles(theme)[
                                              'Image'
                                            ].style,
                                            {
                                              height: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 25,
                                                },
                                                {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 30,
                                                },
                                              ],
                                              width: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 25,
                                                },
                                                {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 30,
                                                },
                                              ],
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      />
                                      {/* Other message */}
                                      <>
                                        {!(
                                          listData?.user_id ===
                                          listData?.user_id
                                        ) ? null : (
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'message_sender'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'message_sender'
                                                ].style,
                                                {
                                                  color:
                                                    palettes.Brand[
                                                      'Strong Inverse'
                                                    ],
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.message}
                                          </Text>
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* User */}
                                <>
                                  {!listData?._chat_of_user ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            palettes.App['Primary 2'],
                                          borderRadius: 50,
                                          flexDirection: 'row',
                                          justifyContent: [
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 'space-between',
                                            },
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 'space-between',
                                            },
                                          ],
                                          marginBottom: 6,
                                          paddingBottom: 4,
                                          paddingLeft: 8,
                                          paddingRight: 8,
                                          paddingTop: 4,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* User message */}
                                      <>
                                        {!listData?._chat_of_user ? null : (
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'user_chat'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'user_chat'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.message}
                                          </Text>
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                              </View>
                            </>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                      />
                    );
                  }}
                </XanoJobzChatApi.FetchJOBZCHATGetAllChatsFromASingleChatroomGET>
              </SimpleStyleKeyboardAwareScrollView>
            </View>
            {/* Send massage */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginBottom: 20,
                  marginTop: 20,
                  minWidth: '100%',
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: { minWidth: Breakpoints.Desktop, value: 20 },
                  position: 'relative',
                },
                dimensions.width
              )}
            >
              {/* Message Input */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newMessageInputValue => {
                  try {
                    setINPUT_message(newMessageInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                onFocus={() => {
                  try {
                    setPLACEHOLDER_message('');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                placeholder={PLACEHOLDER_message.toString()}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  dimensions.width
                )}
                value={INPUT_message}
              />
              {/* Send */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      (
                        await xanoJobzChatJOBZCHATSaveAChatMessagePOST.mutateAsync(
                          {
                            chatroom_id:
                              props.route?.params?.ID_chatroom ??
                              defaultProps.ID_chatroom,
                            message: INPUT_message,
                          }
                        )
                      )?.json;
                      setINPUT_message('');
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  {
                    borderRadius: 30,
                    maxWidth: [
                      { minWidth: Breakpoints.Laptop, value: 200 },
                      { minWidth: Breakpoints.Mobile, value: null },
                    ],
                    minWidth: [
                      { minWidth: Breakpoints.Laptop, value: null },
                      { minWidth: Breakpoints.Mobile, value: null },
                    ],
                    paddingLeft: 16,
                    paddingRight: 16,
                  },
                  dimensions.width
                )}
                title={'Send'}
              />
            </View>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(JobzChatChatsScreen);
