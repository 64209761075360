import React from 'react';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobseekerApi from '../apis/XanoJobseekerApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { current_user: null };

const SuccessScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
    >
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          {/* SCREEN CONTAINER */}
          <View>
            {/* Heading Section */}
            <View
              style={StyleSheet.applyWidth({ margin: 20 }, dimensions.width)}
            >
              {/* Image Section */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginBottom: 10 },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(
                    Images['ElephantLookingLeftJobzMaverickFinal']
                  )}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      { marginBottom: 10 }
                    ),
                    dimensions.width
                  )}
                />
              </View>
              {/* Description */}
              <View>
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {
                    'We have all the information for now that we need. We will now display the Kitchen for you - the central space where you will search for jobs, connect with recruiters, and do anything else you need to handle. \n\nAny questions please email success@jobzcafe.com and we will get back within a short period of time.'
                  }
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      {/* Bottom Button */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignContent: 'flex-end',
            alignItems: 'center',
            alignSelf: 'center',
            bottom: 40,
            flexDirection: { minWidth: Breakpoints.Desktop, value: 'row' },
            justifyContent: { minWidth: Breakpoints.Desktop, value: 'center' },
            marginLeft: 16,
            marginRight: 16,
            maxWidth: 300,
            minWidth: 300,
            position: 'absolute',
          },
          dimensions.width
        )}
      >
        {/* Button_Next */}
        <Button
          iconPosition={'left'}
          onPress={() => {
            const handler = async () => {
              try {
                const jobseekerResult = (
                  await XanoJobseekerApi.oNBOARDINGUpdateStatusPATCH(
                    Constants,
                    { user_id: Constants['USER_ID'] }
                  )
                )?.json;
                navigation.navigate('KitchenJobseekerScreen');
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.branding.secondary,
              borderRadius: 100,
              fontFamily: 'Poppins_700Bold',
              maxWidth: [
                { minWidth: Breakpoints.Laptop, value: 300 },
                { minWidth: Breakpoints.Mobile, value: 300 },
              ],
              minWidth: [
                { minWidth: Breakpoints.Laptop, value: 300 },
                { minWidth: Breakpoints.Mobile, value: 300 },
              ],
            },
            dimensions.width
          )}
          title={'Go to the Kitchen'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(SuccessScreen);
