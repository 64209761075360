import React from 'react';
import {
  Button,
  IconButton,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Spacer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobzZoneApi from '../apis/XanoJobzZoneApi.js';
import NavigationBottomBlock from '../components/NavigationBottomBlock';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { gotojobDetail: 1 };

const JobzZoneJobDetailScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { flex: { minWidth: Breakpoints.Tablet, value: 1 } },
        dimensions.width
      )}
    >
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          {/* back navigation */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
              },
              dimensions.width
            )}
          >
            {/* Back */}
            <IconButton
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              icon={'AntDesign/arrowleft'}
            />
          </View>
          {/* SCREEN CONTAINER */}
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, justifyContent: 'space-between' },
              dimensions.width
            )}
          >
            <SimpleStyleKeyboardAwareScrollView
              enableAutomaticScroll={false}
              enableOnAndroid={false}
              enableResetScrollToCoords={false}
              keyboardShouldPersistTaps={'never'}
              showsVerticalScrollIndicator={true}
              viewIsInsideTabBar={false}
              style={StyleSheet.applyWidth(
                {
                  flex: 1,
                  maxHeight: [
                    { minWidth: Breakpoints.Tablet, value: 1040 },
                    { minWidth: Breakpoints.Mobile, value: 700 },
                    { minWidth: Breakpoints.BigScreen, value: 1200 },
                    { minWidth: Breakpoints.Laptop, value: 1100 },
                    { minWidth: Breakpoints.Tablet, value: dimensions },
                    { minWidth: Breakpoints.Mobile, value: dimensions },
                  ],
                  paddingLeft: 20,
                  paddingRight: 20,
                },
                dimensions.width
              )}
            >
              <XanoJobzZoneApi.FetchJOBZZONEGetSingleJobGET
                job_id={
                  props.route?.params?.gotojobDetail ??
                  defaultProps.gotojobDetail
                }
              >
                {({ loading, error, data, refetchJOBZZONEGetSingleJob }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      <View>
                        {/* Header */}
                        <View>
                          {/* Job Title */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['h2'].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['h2'].style,
                              dimensions.width
                            )}
                          >
                            {fetchData?.job_name}
                          </Text>

                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            {/* Company Name */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['h3'].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['h3'].style,
                                dimensions.width
                              )}
                            >
                              {fetchData?.hiring_company}
                              {' • '}
                            </Text>
                            {/* Location */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['h3'].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['h3'].style,
                                dimensions.width
                              )}
                            >
                              {fetchData?.locality}
                            </Text>
                          </View>
                        </View>
                      </View>
                      {/* Body */}
                      <View>
                        {/* Cardbody */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flex: 1,
                              justifyContent: 'space-around',
                              minHeight: 50,
                              padding: 10,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Chip Row */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row', gap: 6 },
                              dimensions.width
                            )}
                          >
                            {/* Chip_State */}
                            <View
                              {...GlobalStyles.ViewStyles(theme)['chip'].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.ViewStyles(theme)['chip'].style,
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.branding.primary,
                                    fontFamily: 'Poppins_500Medium',
                                    fontSize: 12,
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                {fetchData?.state}
                              </Text>
                            </View>
                            {/* Chip_Role */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor: palettes.App['Primary 2'],
                                  borderRadius: 50,
                                  flex: 1,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  paddingTop: 8,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.branding.primary,
                                    fontFamily: 'Poppins_500Medium',
                                    fontSize: 12,
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                {fetchData?.role_type}
                              </Text>
                            </View>
                            {/* Chip_Employment */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor: palettes.App['Primary 2'],
                                  borderRadius: 50,
                                  flex: 1,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  paddingTop: 8,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.branding.primary,
                                    fontFamily: 'Poppins_500Medium',
                                    fontSize: 12,
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                {fetchData?.employment_time}
                              </Text>
                            </View>
                          </View>
                          <Spacer bottom={8} left={8} right={8} top={12} />
                        </View>
                        {/* Eduction Industy Category */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              borderColor: theme.colors.border.brand,
                              borderRadius: 12,
                              borderWidth: 1,
                              flexDirection: 'column',
                              padding: 8,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Label Education */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['h3 bold'].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['h3 bold'].style,
                              dimensions.width
                            )}
                          >
                            {'Education'}
                          </Text>
                          {/* Education */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {fetchData?.education_required?.toUpperCase()}
                          </Text>
                          {/* Label Categories */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['h3 bold'].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['h3 bold'].style,
                              dimensions.width
                            )}
                          >
                            {'Categories'}
                          </Text>
                          {/* Categories */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {fetchData?.job_function}
                          </Text>
                          {/* Label Industries */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['h3 bold'].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['h3 bold'].style,
                              dimensions.width
                            )}
                          >
                            {'Industries'}
                          </Text>
                          {/* Industry */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['h4'].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['h4'].style,
                              dimensions.width
                            )}
                          >
                            {fetchData?.industries}
                          </Text>
                        </View>
                        {/* Apply */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flex: 1,
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                              minHeight: 50,
                              paddingTop: 8,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Button_Apply */}
                          <Button
                            iconPosition={'left'}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  await WebBrowser.openBrowserAsync(
                                    `${fetchData?.url}`
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor:
                                  theme.colors.branding.secondary,
                                borderRadius: 50,
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 12,
                                minHeight: 0,
                                paddingBottom: 4,
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 4,
                              },
                              dimensions.width
                            )}
                            title={'APPLY'}
                          />
                        </View>
                      </View>
                      {/* Job Section */}
                      <View>
                        {/* Label Job Details */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['h3 bold'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['h3 bold'].style,
                            dimensions.width
                          )}
                        >
                          {'Job Details'}
                        </Text>
                        {/* Job Details */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            dimensions.width
                          )}
                        >
                          {fetchData?.job_details}
                        </Text>
                      </View>
                      {/* Bottom Section */}
                      <View
                        style={StyleSheet.applyWidth(
                          { paddingTop: 20 },
                          dimensions.width
                        )}
                      >
                        {/* Apply 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flex: 1,
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                              minHeight: 50,
                              paddingTop: 8,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Button_Apply */}
                          <Button
                            iconPosition={'left'}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  await WebBrowser.openBrowserAsync(
                                    `${fetchData?.url}`
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor:
                                  theme.colors.branding.secondary,
                                borderRadius: 50,
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 12,
                                minHeight: 0,
                                paddingBottom: 4,
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 4,
                              },
                              dimensions.width
                            )}
                            title={'APPLY'}
                          />
                        </View>
                      </View>
                    </>
                  );
                }}
              </XanoJobzZoneApi.FetchJOBZZONEGetSingleJobGET>
            </SimpleStyleKeyboardAwareScrollView>
          </View>
        </View>
        <NavigationBottomBlock />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(JobzZoneJobDetailScreen);
