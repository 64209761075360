import React from 'react';
import {
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  StarRating,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XANOJobzR8terApi from '../apis/XANOJobzR8terApi.js';
import BackNavigationBlock from '../components/BackNavigationBlock';
import NavigationBottomBlock from '../components/NavigationBottomBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import convertTime from '../global-functions/convertTime';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const JobzR8terListScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [show_findRecruiter, setShow_findRecruiter] = React.useState(false);
  const [starRatingValue, setStarRatingValue] = React.useState(0);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'SELECTED',
        value: '',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          <BackNavigationBlock />
          {/* SCREEN CONTAINER */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'space-between' },
              dimensions.width
            )}
          >
            {/* Heading */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 20,
                  marginLeft: 20,
                  marginRight: 20,
                },
                dimensions.width
              )}
            >
              {/* jobR8ter */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['h2'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['h2'].style,
                  dimensions.width
                )}
              >
                {'jobzR8ter'}
              </Text>
              {/* Search Ratings */}
              <IconButton
                onPress={() => {
                  try {
                    setShow_findRecruiter(true);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                icon={'MaterialCommunityIcons/book-search-outline'}
              />
            </View>
            {/* RATING CONTAINER */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, marginLeft: 20, marginRight: 20 },
                dimensions.width
              )}
            >
              <SimpleStyleScrollView
                bounces={true}
                horizontal={false}
                keyboardShouldPersistTaps={'never'}
                nestedScrollEnabled={false}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
              />
              {/* Heading */}
              <View>
                {/* Description */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {'These are the recruiters you have rated.'}
                </Text>
              </View>
              {/* ALL RATINGS */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 20 },
                  dimensions.width
                )}
              >
                <XANOJobzR8terApi.FetchGetAllRatingsByLoggedInUserGET>
                  {({
                    loading,
                    error,
                    data,
                    refetchGetAllRatingsByLoggedInUser,
                  }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <SimpleStyleFlatList
                        data={fetchData}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ??
                          listData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'Szt0dJiF'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <>
                              {/* LIST CONTAINER */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'flex-start',
                                    flex: 1,
                                    flexDirection: 'row',
                                    marginBottom: 10,
                                    marginLeft: 20,
                                    marginRight: 20,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Left Column */}
                                <View>
                                  <Pressable
                                    onPress={() => {
                                      try {
                                        navigation.navigate(
                                          'JobzConnectRecruiterUserProfileScreen',
                                          {
                                            ID_selectedUser:
                                              listData?._userRecruiter?.id,
                                          }
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <Image
                                      resizeMode={'cover'}
                                      {...GlobalStyles.ImageStyles(theme)[
                                        'Image'
                                      ].props}
                                      source={imageSource(
                                        `${listData?._userRecruiter?._image_of_user?.image?.url}`
                                      )}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.ImageStyles(theme)['Image']
                                          .style,
                                        dimensions.width
                                      )}
                                    />
                                  </Pressable>
                                </View>
                                {/* Right Column */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flex: 1,
                                      flexDirection: 'column',
                                      paddingLeft: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Pressable
                                    onPress={() => {
                                      try {
                                        navigation.navigate(
                                          'JobzR8terIndividualScreen',
                                          {
                                            recruiterID:
                                              listData?._userRecruiter?.id,
                                            rating_id: listData?.id,
                                          }
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    {/* Star Rating */}
                                    <View>
                                      <StarRating
                                        activeColor={
                                          theme.colors.branding.primary
                                        }
                                        inactiveColor={
                                          theme.colors.border.brand
                                        }
                                        isEditable={true}
                                        maxStars={5}
                                        starSize={16}
                                        defaultValue={listData?.combined_score}
                                      />
                                    </View>
                                    {/* Name */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { flexDirection: 'row' },
                                        dimensions.width
                                      )}
                                    >
                                      {/* First Name */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)['h2']
                                          .props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)['h2']
                                              .style,
                                            { marginRight: 4 }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {listData?._userRecruiter?.first_name}
                                      </Text>
                                      {/* Last Name */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)['h2']
                                          .props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)['h2']
                                            .style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?._userRecruiter?.last_name}
                                      </Text>
                                    </View>
                                  </Pressable>
                                  {/* Demo User */}
                                  <View>
                                    {/* Demo */}
                                    <>
                                      {!listData?._userRecruiter
                                        ?.demo ? null : (
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'DEMO USER'}
                                        </Text>
                                      )}
                                    </>
                                  </View>
                                  {/* LABEL Rated Date */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flexDirection: 'row', gap: 8 },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Rating  Date */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Rated: '}
                                      {convertTime(listData?.created_at)}
                                    </Text>
                                    {/* First Recieved */}
                                    <View>
                                      {/* Recieved */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'h3 bold'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'h3 bold'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {null}
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                      />
                    );
                  }}
                </XANOJobzR8terApi.FetchGetAllRatingsByLoggedInUserGET>
              </View>
            </View>
          </View>
        </View>
      </View>
      <NavigationBottomBlock />
    </ScreenContainer>
  );
};

export default withTheme(JobzR8terListScreen);
