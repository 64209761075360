import React from 'react';
import {
  Button,
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoOptionSets$DropdownsApi from '../apis/XanoOptionSets$DropdownsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { newCompany: null, newLocation: null };

const AddNewLocationScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [ID_state, setID_state] = React.useState(0);
  const [INPUT_City, setINPUT_City] = React.useState('');
  const [INPUT_State, setINPUT_State] = React.useState('');
  const [PLACEHOLDER_City, setPLACEHOLDER_City] = React.useState(
    'Enter the name of your City'
  );
  const [PLACEHOLDER_State, setPLACEHOLDER_State] = React.useState(
    'Enter the name of your State'
  );
  const [isEditingState, setIsEditingState] = React.useState(false);
  const [searchStateValue, setSearchStateValue] = React.useState('');

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          {/* NEW Location Block */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.Brand['Strong Inverse'],
                flex: 1,
                minHeight: 500,
                position: 'absolute',
                width: '100%',
                zIndex: 9999,
              },
              dimensions.width
            )}
          >
            {/* back navigation */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                },
                dimensions.width
              )}
            >
              {/* Back */}
              <IconButton
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                icon={'AntDesign/arrowleft'}
              />
            </View>
            {/* HEADING Add the new Location */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['h2'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['h2'].style, {
                  marginLeft: 20,
                  marginTop: 10,
                }),
                dimensions.width
              )}
            >
              {'Add new Location'}
            </Text>
            {/* LOCATION CONTAINER */}
            <View
              style={StyleSheet.applyWidth(
                { marginLeft: 20, marginRight: 20, marginTop: 20 },
                dimensions.width
              )}
            >
              {/* LABEL City */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['h3'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['h3'].style,
                  dimensions.width
                )}
              >
                {'City'}
              </Text>
              {/* Input Add_City */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newInputAddCityValue => {
                  try {
                    setINPUT_City(newInputAddCityValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                placeholder={PLACEHOLDER_City.toString()}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                    { marginTop: 8 }
                  ),
                  dimensions.width
                )}
                value={INPUT_City}
              />
              {/* LABEL State */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['h3'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['h3'].style,
                    { marginTop: 8 }
                  ),
                  dimensions.width
                )}
              >
                {'State'}
              </Text>
              {/* Find State */}
              <View>
                {/* Input_State */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newInputStateValue => {
                    try {
                      setSearchStateValue(newInputStateValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onFocus={() => {
                    try {
                      setIsEditingState(true);
                      setINPUT_State('');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                  placeholder={PLACEHOLDER_State.toString()}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                      { marginTop: 8 }
                    ),
                    dimensions.width
                  )}
                  value={searchStateValue}
                />
                <>
                  {!(searchStateValue?.length && isEditingState) ? null : (
                    <XanoOptionSets$DropdownsApi.FetchDropDownStatesGET
                      search={searchStateValue}
                    >
                      {({ loading, error, data, refetchDropDownStates }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            {/* MESSAGE Add Your State */}
                            <>
                              {fetchData?.dd_states?.length ? null : (
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {'That state doesnt exist, please re-enter.'}
                                </Text>
                              )}
                            </>
                            <>
                              {searchStateValue?.length > 2 ? null : (
                                <SimpleStyleFlatList
                                  data={fetchData?.dd_states}
                                  horizontal={false}
                                  inverted={false}
                                  keyExtractor={(listData, index) =>
                                    listData?.id ??
                                    listData?.uuid ??
                                    index?.toString() ??
                                    JSON.stringify(listData)
                                  }
                                  keyboardShouldPersistTaps={'never'}
                                  listKey={'yrJ9OnG8'}
                                  nestedScrollEnabled={false}
                                  numColumns={1}
                                  onEndReachedThreshold={0.5}
                                  renderItem={({ item, index }) => {
                                    const listData = item;
                                    return (
                                      <Pressable
                                        onPress={() => {
                                          try {
                                            setSearchStateValue(
                                              listData?.state
                                            );
                                            setINPUT_State('newStateValue');
                                            setID_state(listData?.id);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        {/* State */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.state}
                                          {', '}
                                          {listData?.abbreviation}
                                        </Text>
                                      </Pressable>
                                    );
                                  }}
                                  showsHorizontalScrollIndicator={true}
                                  showsVerticalScrollIndicator={true}
                                />
                              )}
                            </>
                          </>
                        );
                      }}
                    </XanoOptionSets$DropdownsApi.FetchDropDownStatesGET>
                  )}
                </>
              </View>
            </View>
            {/* BUTTON _SAVE LOCATION */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  margin: 20,
                },
                dimensions.width
              )}
            >
              {/* Button_Save Location */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      const newLocationResult = (
                        await XanoOptionSets$DropdownsApi.addANewLocationPOST(
                          Constants,
                          { city: INPUT_City, stateID: ID_state }
                        )
                      )?.json;
                      navigation.navigate('AddNewCompanyScreen', {
                        newCompanyResponse:
                          props.route?.params?.newCompany ??
                          defaultProps.newCompany,
                        newLocationResult:
                          props.route?.params?.newLocation ??
                          defaultProps.newLocation,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button_Secondary'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button_Secondary'].style,
                    {
                      fontFamily: 'Poppins_700Bold',
                      height: null,
                      maxWidth: 60,
                    }
                  ),
                  dimensions.width
                )}
                title={'Save'}
              />
            </View>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AddNewLocationScreen);
