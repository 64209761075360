import React from 'react';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoRecruiterApi from '../apis/XanoRecruiterApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { recruiter_ID: 30 };

const Onboarding3RecruiterScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [showMessage, setShowMessage] = React.useState(false);
  const [showYourDone, setShowYourDone] = React.useState(false);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
    >
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          {/* SCREEN CONTAINER */}
          <View>
            {/* Heaing Section */}
            <View
              style={StyleSheet.applyWidth({ margin: 20 }, dimensions.width)}
            >
              {/* Image Section */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginBottom: 10 },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(
                    Images['ElephantLookingLeftJobzMaverickFinal']
                  )}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      { marginBottom: 10 }
                    ),
                    dimensions.width
                  )}
                />
              </View>
              {/* Sub Heading */}
              <View
                style={StyleSheet.applyWidth(
                  { marginBottom: 10, marginTop: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['h2'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['h2'].style,
                    dimensions.width
                  )}
                >
                  {"Lastly, let's see what you look like . . ."}
                </Text>
              </View>
              {/* Description */}
              <View>
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {
                    'We all want to be able to know that we are speaking with a real person. \n\nThink about a candidate turning up to an interview already knowing what you look like. \n\nTheir stress level will already be reduced just by this.'
                  }
                </Text>
              </View>
            </View>
          </View>
          {/* Image Container */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'cover'}
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              source={imageSource(`${Constants['uploadIMAGE']}`)}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    borderColor: theme.colors.border.brand,
                    borderWidth: 1,
                    height: 200,
                    width: 200,
                  }
                ),
                dimensions.width
              )}
            />
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  gap: 10,
                  justifyContent: 'space-evenly',
                  marginTop: 10,
                },
                dimensions.width
              )}
            >
              {/* Browse */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      const uploadimageResult = await openImagePickerUtil({
                        mediaTypes: 'Images',
                        allowsEditing: false,
                        quality: 0.2,
                        allowsMultipleSelection: false,
                        permissionErrorMessage:
                          'Sorry, we need media library permissions to make this work.',
                        showAlertOnPermissionError: true,
                        outputBase64: true,
                      });

                      setGlobalVariableValue({
                        key: 'uploadIMAGE',
                        value: uploadimageResult,
                      });
                      setShowYourDone(true);
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderRadius: 100,
                    color: theme.colors.branding.primary,
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 12,
                    minWidth: 40,
                  },
                  dimensions.width
                )}
                title={'Browse'}
              />
            </View>
          </View>
          {/* MESSAGE */}
          <View
            style={StyleSheet.applyWidth(
              { marginLeft: 20, marginRight: 20 },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Text'].style,
                dimensions.width
              )}
            >
              {'Once you upload a picture the Your Done button will appear.'}
            </Text>
          </View>
        </View>
      </View>
      {/* Bottom Button */}
      <>
        {!(showYourDone === true) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'flex-end',
                alignItems: 'center',
                alignSelf: 'center',
                bottom: 40,
                flexDirection: { minWidth: Breakpoints.Desktop, value: 'row' },
                justifyContent: {
                  minWidth: Breakpoints.Desktop,
                  value: 'center',
                },
                marginLeft: 16,
                marginRight: 16,
                maxWidth: 300,
                minWidth: 300,
                position: 'absolute',
              },
              dimensions.width
            )}
          >
            {/* Button_Next */}
            <Button
              iconPosition={'left'}
              onPress={() => {
                const handler = async () => {
                  try {
                    if (Constants['uploadIMAGE'] === '') {
                      setShowMessage(true);
                    } else {
                    }

                    (
                      await XanoRecruiterApi.oNBOARDINGUploadProfilePicturePATCH(
                        Constants,
                        { content: Constants['uploadIMAGE'] }
                      )
                    )?.json;
                    navigation.navigate('SuccessScreen');
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.branding.secondary,
                  borderRadius: 100,
                  fontFamily: 'Poppins_700Bold',
                  maxWidth: [
                    { minWidth: Breakpoints.Laptop, value: 300 },
                    { minWidth: Breakpoints.Mobile, value: 300 },
                  ],
                  minWidth: [
                    { minWidth: Breakpoints.Laptop, value: 300 },
                    { minWidth: Breakpoints.Mobile, value: 300 },
                  ],
                },
                dimensions.width
              )}
              title={'Your Done'}
            />
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(Onboarding3RecruiterScreen);
