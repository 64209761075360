import React from 'react';
import {
  Icon,
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  SimpleStyleSwipeableList,
  SwipeableItem,
  SwipeableItemButton,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobzConnectApi from '../apis/XanoJobzConnectApi.js';
import NavigationBottomBlock from '../components/NavigationBottomBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import connectSelected from '../global-functions/connectSelected';
import convertTime from '../global-functions/convertTime';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const JobzConnectActivityDetailScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [showEducationSelected, setShowEducationSelected] =
    React.useState(false);
  const [showRight, setShowRight] = React.useState(false);
  const [showWrong, setShowWrong] = React.useState(false);
  const [show_connect_sent, setShow_connect_sent] = React.useState(false);
  const [show_connects_favorered, setShow_connects_favorered] =
    React.useState(false);
  const [show_connects_received, setShow_connects_received] =
    React.useState(true);
  const [show_jobzSelected, setShow_jobzSelected] = React.useState(false);
  const [show_screenContainer, setShow_screenContainer] = React.useState(true);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setShow_connects_received(true);
      setShow_connect_sent(false);
      setGlobalVariableValue({
        key: 'CONNECT',
        value: 'recieved',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
    >
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
            { minWidth: '100%' }
          ),
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          {/* back navigation */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
              },
              dimensions.width
            )}
          >
            {/* Back */}
            <IconButton
              onPress={() => {
                try {
                  if (navigation.canGoBack()) {
                    navigation.popToTop();
                  }
                  navigation.replace('JobzConnectActivityScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              icon={'AntDesign/arrowleft'}
            />
          </View>
          {/* SCREEN CONTAINER */}
          <>
            {!(show_screenContainer === true) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    justifyContent: [
                      { minWidth: Breakpoints.Mobile, value: 'space-between' },
                      { minWidth: Breakpoints.Tablet, value: 'flex-start' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Heading */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, marginLeft: 20 },
                    dimensions.width
                  )}
                >
                  {/* jobzConnect */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['h2'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['h2'].style,
                      dimensions.width
                    )}
                  >
                    {'jobzConnect - Activity'}
                  </Text>
                </View>
                {/* TABS */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 20, paddingBottom: 10 },
                    dimensions.width
                  )}
                >
                  <XanoJobzConnectApi.FetchGetNumberOfMatchesGET>
                    {({ loading, error, data, refetchGetNumberOfMatches }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <>
                          {/* TAB Recieved Selected */}
                          <>
                            {!(show_connects_received === true) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  { flexDirection: 'row' },
                                  dimensions.width
                                )}
                              >
                                {/* Left Column */}
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'tab selected'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ViewStyles(theme)[
                                        'tab selected'
                                      ].style,
                                      { paddingBottom: 10 }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  <Pressable
                                    onPress={() => {
                                      try {
                                        setShow_connects_received(true);
                                        setShow_connect_sent(false);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    {/* Recieved */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'text selected'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'text selected'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Recieved'}
                                      {/* Result */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)['h2']
                                          .props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)['h2']
                                              .style,
                                            {
                                              color: connectSelected(
                                                'recieved',
                                                Constants['CONNECT']
                                              ),
                                              paddingLeft: 4,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'( '}
                                        {fetchData?.recipient_count}
                                        {' )'}
                                      </Text>
                                    </Text>
                                  </Pressable>
                                </View>
                                {/* Right Column */}
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'tab unselected'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ViewStyles(theme)[
                                        'tab unselected'
                                      ].style,
                                      { marginRight: null, paddingBottom: 10 }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  <Pressable
                                    onPress={() => {
                                      try {
                                        setShow_connect_sent(true);
                                        setShow_connects_received(false);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    {/* Sent */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'text unselected'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'text unselected'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Sent'}
                                      {/* Result */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)['h2']
                                          .props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)['h2']
                                              .style,
                                            {
                                              color: connectSelected(
                                                'sent',
                                                Constants['CONNECT']
                                              ),
                                              paddingLeft: 4,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'( '}
                                        {fetchData?.initiator_count}
                                        {' )'}
                                      </Text>
                                    </Text>
                                  </Pressable>
                                </View>
                              </View>
                            )}
                          </>
                          {/* TAB Sent Selected */}
                          <>
                            {!(show_connect_sent === true) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  { flexDirection: 'row' },
                                  dimensions.width
                                )}
                              >
                                {/* Left Column */}
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'tab unselected'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ViewStyles(theme)[
                                        'tab unselected'
                                      ].style,
                                      {
                                        alignContent: 'center',
                                        paddingBottom: 10,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  <Pressable
                                    onPress={() => {
                                      try {
                                        setShow_connect_sent(false);
                                        setShow_connects_received(true);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    {/* Recieved */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'text unselected'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'text unselected'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Recieved'}
                                      {/* Result */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)['h2']
                                          .props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)['h2']
                                              .style,
                                            {
                                              color: 'recieved',
                                              paddingLeft: 4,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'( '}
                                        {fetchData?.recipient_count}
                                        {' )'}
                                      </Text>
                                    </Text>
                                  </Pressable>
                                </View>
                                {/* Right Column */}
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'tab selected'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ViewStyles(theme)[
                                        'tab selected'
                                      ].style,
                                      { marginRight: null, paddingBottom: 10 }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  <Pressable
                                    onPress={() => {
                                      try {
                                        setShow_connect_sent(true);
                                        setShow_connects_received(false);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    {/* Sent */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'text unselected'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'text unselected'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Sent'}
                                      {/* Result */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)['h2']
                                          .props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)['h2']
                                              .style,
                                            { color: 'sent', paddingLeft: 4 }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'( '}
                                        {fetchData?.initiator_count}
                                        {' )'}
                                      </Text>
                                    </Text>
                                  </Pressable>
                                </View>
                              </View>
                            )}
                          </>
                        </>
                      );
                    }}
                  </XanoJobzConnectApi.FetchGetNumberOfMatchesGET>
                </View>
                {/* Connection Container */}
                <View>
                  <SimpleStyleScrollView
                    bounces={true}
                    horizontal={false}
                    keyboardShouldPersistTaps={'never'}
                    nestedScrollEnabled={false}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                    style={StyleSheet.applyWidth(
                      { marginLeft: 10, maxHeight: 590, minHeight: 590 },
                      dimensions.width
                    )}
                  >
                    {/* Headings */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: 'column',
                          marginLeft: 20,
                          marginRight: 20,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Connects Recieved */}
                      <>
                        {!(show_connects_received === true) ? null : (
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { marginBottom: 10, marginRight: 20 }
                              ),
                              dimensions.width
                            )}
                          >
                            {
                              'These are the people who have requested to connect with you . . . '
                            }
                          </Text>
                        )}
                      </>
                      {/* Connects Sent */}
                      <>
                        {!(show_connect_sent === true) ? null : (
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { marginBottom: 10, marginRight: 20 }
                              ),
                              dimensions.width
                            )}
                          >
                            {
                              'These are the people who you have sent connections . . . '
                            }
                          </Text>
                        )}
                      </>
                    </View>
                    {/* RECIEVED */}
                    <View>
                      <XanoJobzConnectApi.FetchGetAllRecievedConnectionsGET>
                        {({
                          loading,
                          error,
                          data,
                          refetchGetAllRecievedConnections,
                        }) => {
                          const fetchData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <>
                              {/* Swipeable List Recieved */}
                              <>
                                {!(show_connects_received === true) ? null : (
                                  <SimpleStyleSwipeableList
                                    data={fetchData}
                                    disableScrollWhenSwiping={true}
                                    estimatedItemSize={50}
                                    horizontal={false}
                                    inverted={false}
                                    keyExtractor={(
                                      swipeableListRecievedData,
                                      index
                                    ) =>
                                      swipeableListRecievedData?.id ??
                                      swipeableListRecievedData?.uuid ??
                                      index?.toString() ??
                                      JSON.stringify(swipeableListRecievedData)
                                    }
                                    keyboardShouldPersistTaps={'never'}
                                    listComponent={'FlatList'}
                                    listKey={'Y3KOcQtr'}
                                    nestedScrollEnabled={false}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    renderItem={({ item, index }) => {
                                      const swipeableListRecievedData = item;
                                      return (
                                        <SwipeableItem
                                          closeOnPress={true}
                                          friction={20}
                                          swipeActivationPercentage={80}
                                          swipeToClosePercent={50}
                                          swipeToOpenPercent={50}
                                          {...GlobalStyles.SwipeableItemStyles(
                                            theme
                                          )['Swipeable Item'].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.SwipeableItemStyles(
                                              theme
                                            )['Swipeable Item'].style,
                                            dimensions.width
                                          )}
                                        >
                                          {/* Recieved Main Container */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { marginTop: 0 },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Recieved Container */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'flex-start',
                                                  flex: 1,
                                                  flexDirection: 'row',
                                                  marginBottom: 10,
                                                  marginLeft: 20,
                                                  marginRight: 20,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Left Column */}
                                              <View>
                                                <Pressable
                                                  onPress={() => {
                                                    try {
                                                      if (
                                                        swipeableListRecievedData
                                                          ?._user_initiator
                                                          ?._os_externalrole
                                                          ?.id === 1
                                                      ) {
                                                        navigation.navigate(
                                                          'JobzConnectJobseekerUserProfileScreen',
                                                          {
                                                            ID_selectedUser:
                                                              swipeableListRecievedData
                                                                ?._user_initiator
                                                                ?.id,
                                                          }
                                                        );
                                                      } else {
                                                        navigation.navigate(
                                                          'JobzConnectRecruiterUserProfileScreen',
                                                          {
                                                            ID_selectedUser:
                                                              swipeableListRecievedData
                                                                ?._user_initiator
                                                                ?.id,
                                                          }
                                                        );
                                                      }
                                                    } catch (err) {
                                                      console.error(err);
                                                    }
                                                  }}
                                                >
                                                  <Image
                                                    resizeMode={'cover'}
                                                    {...GlobalStyles.ImageStyles(
                                                      theme
                                                    )['Image'].props}
                                                    source={imageSource(
                                                      `${swipeableListRecievedData?._user_initiator?._image_of_user?.image?.url}`
                                                    )}
                                                    style={StyleSheet.applyWidth(
                                                      GlobalStyles.ImageStyles(
                                                        theme
                                                      )['Image'].style,
                                                      dimensions.width
                                                    )}
                                                  />
                                                </Pressable>
                                              </View>
                                              {/* Right Column */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    flex: 1,
                                                    flexDirection: 'column',
                                                    paddingLeft: 20,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Name */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { flexDirection: 'row' },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* First Name */}
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['h2'].props}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['h2'].style,
                                                        { marginRight: 4 }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      swipeableListRecievedData
                                                        ?._user_initiator
                                                        ?.first_name
                                                    }
                                                  </Text>
                                                  {/* Last Name */}
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['h2'].props}
                                                    style={StyleSheet.applyWidth(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['h2'].style,
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      swipeableListRecievedData
                                                        ?._user_initiator
                                                        ?.last_name
                                                    }
                                                  </Text>
                                                </View>
                                                {/* Demo User */}
                                                <View>
                                                  {/* Demo */}
                                                  <>
                                                    {!swipeableListRecievedData
                                                      ?._user_initiator
                                                      ?.demo ? null : (
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['Text'].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {'DEMO USER'}
                                                      </Text>
                                                    )}
                                                  </>
                                                </View>
                                                {/* Role */}
                                                <View>
                                                  {/* Role */}
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['h3'].props}
                                                    style={StyleSheet.applyWidth(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['h3'].style,
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      swipeableListRecievedData
                                                        ?._user_initiator
                                                        ?._os_externalrole
                                                        ?.external_role
                                                    }
                                                  </Text>
                                                </View>
                                                {/* Status */}
                                                <View>
                                                  {/* Status */}
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'].props}
                                                    style={StyleSheet.applyWidth(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].style,
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      swipeableListRecievedData?.status
                                                    }
                                                  </Text>
                                                </View>
                                                {/* LABEL Sent Date */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      flexDirection: 'row',
                                                      gap: 8,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Received DAte */}
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'].props}
                                                    style={StyleSheet.applyWidth(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].style,
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'Recieved Date:'}
                                                  </Text>
                                                  {/* Recieved Date */}
                                                  <View>
                                                    {/* Status */}
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['h3 bold'].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['h3 bold'].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {convertTime(
                                                        swipeableListRecievedData?.created_at
                                                      )}
                                                    </Text>
                                                  </View>
                                                </View>
                                              </View>
                                            </View>
                                          </View>
                                          {/* Swipeable Item Button UnMatch */}
                                          <SwipeableItemButton
                                            closeOnPress={true}
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  (
                                                    await XanoJobzConnectApi.unmatchByRecipientPATCH(
                                                      Constants,
                                                      {
                                                        match_id:
                                                          swipeableListRecievedData?.id,
                                                      }
                                                    )
                                                  )?.json;
                                                  await refetchGetAllRecievedConnections();
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                            revealSwipeDirection={'left'}
                                            backgroundColor={
                                              theme.colors.background.danger
                                            }
                                            icon={'AntDesign/closecircleo'}
                                            title={'Reject'}
                                          />
                                          {/* Swipeable Item Button Match */}
                                          <SwipeableItemButton
                                            closeOnPress={true}
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  (
                                                    await XanoJobzConnectApi.acceptMatchPATCH(
                                                      Constants,
                                                      {
                                                        match_id:
                                                          swipeableListRecievedData?.id,
                                                      }
                                                    )
                                                  )?.json;
                                                  await refetchGetAllRecievedConnections();
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                            revealSwipeDirection={'left'}
                                            backgroundColor={
                                              theme.colors.branding.secondary
                                            }
                                            icon={'AntDesign/check'}
                                            title={'Match'}
                                          />
                                        </SwipeableItem>
                                      );
                                    }}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                    style={StyleSheet.applyWidth(
                                      { flex: 1 },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                            </>
                          );
                        }}
                      </XanoJobzConnectApi.FetchGetAllRecievedConnectionsGET>
                    </View>
                    {/* SENT */}
                    <View>
                      <XanoJobzConnectApi.FetchGetAllSentConnectionsGET>
                        {({
                          loading,
                          error,
                          data,
                          refetchGetAllSentConnections,
                        }) => {
                          const fetchData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <>
                              {/* Swipeable List Sent */}
                              <>
                                {!(show_connect_sent === true) ? null : (
                                  <SimpleStyleSwipeableList
                                    data={fetchData}
                                    disableScrollWhenSwiping={true}
                                    estimatedItemSize={50}
                                    horizontal={false}
                                    inverted={false}
                                    keyExtractor={(
                                      swipeableListSentData,
                                      index
                                    ) =>
                                      swipeableListSentData?.id ??
                                      swipeableListSentData?.uuid ??
                                      index?.toString() ??
                                      JSON.stringify(swipeableListSentData)
                                    }
                                    keyboardShouldPersistTaps={'never'}
                                    listComponent={'FlatList'}
                                    listKey={'aiK3NSlJ'}
                                    nestedScrollEnabled={false}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    renderItem={({ item, index }) => {
                                      const swipeableListSentData = item;
                                      return (
                                        <SwipeableItem
                                          closeOnPress={true}
                                          friction={20}
                                          swipeActivationPercentage={80}
                                          swipeToClosePercent={50}
                                          swipeToOpenPercent={50}
                                          {...GlobalStyles.SwipeableItemStyles(
                                            theme
                                          )['Swipeable Item'].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.SwipeableItemStyles(
                                              theme
                                            )['Swipeable Item'].style,
                                            dimensions.width
                                          )}
                                        >
                                          {/* Sent Main Container */}
                                          <>
                                            {!(
                                              show_connect_sent === true
                                            ) ? null : (
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { flex: 1 },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Sent Container */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      flexDirection: 'row',
                                                      marginBottom: 10,
                                                      marginLeft: 20,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* LEFT COLUMN */}
                                                  <View>
                                                    <Pressable
                                                      onPress={() => {
                                                        try {
                                                          if (
                                                            swipeableListSentData
                                                              ?._user_recipient
                                                              ?._os_externalrole
                                                              ?.external_role ===
                                                            1
                                                          ) {
                                                            navigation.navigate(
                                                              'JobzConnectJobseekerUserProfileScreen',
                                                              {
                                                                ID_selectedUser:
                                                                  swipeableListSentData
                                                                    ?._user_recipient
                                                                    ?.id,
                                                              }
                                                            );
                                                          } else {
                                                            navigation.navigate(
                                                              'JobzConnectRecruiterUserProfileScreen',
                                                              {
                                                                ID_selectedUser:
                                                                  swipeableListSentData
                                                                    ?._user_recipient
                                                                    ?.id,
                                                              }
                                                            );
                                                          }
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      }}
                                                    >
                                                      <Image
                                                        resizeMode={'cover'}
                                                        {...GlobalStyles.ImageStyles(
                                                          theme
                                                        )['Image'].props}
                                                        source={imageSource(
                                                          `${swipeableListSentData?._user_recipient?._image_of_user?.image?.url}`
                                                        )}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.ImageStyles(
                                                            theme
                                                          )['Image'].style,
                                                          dimensions.width
                                                        )}
                                                      />
                                                    </Pressable>
                                                  </View>
                                                  {/* RIGHT COLUMN */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        flex: 1,
                                                        flexDirection: 'column',
                                                        paddingLeft: 20,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* Name */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          flexDirection: 'row',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* First Name */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )['h2'].props}
                                                        style={StyleSheet.applyWidth(
                                                          StyleSheet.compose(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )['h2'].style,
                                                            { marginRight: 4 }
                                                          ),
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {
                                                          swipeableListSentData
                                                            ?._user_recipient
                                                            ?.first_name
                                                        }
                                                      </Text>
                                                      {/* Last Name */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )['h2'].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['h2'].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {
                                                          swipeableListSentData
                                                            ?._user_recipient
                                                            ?.last_name
                                                        }
                                                      </Text>
                                                    </View>
                                                    {/* Demo User */}
                                                    <View>
                                                      <>
                                                        {!swipeableListSentData
                                                          ?._user_recipient
                                                          ?.demo ? null : (
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )['Text'].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )['Text'].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {'DEMO USER'}
                                                          </Text>
                                                        )}
                                                      </>
                                                    </View>
                                                    {/* Role */}
                                                    <View>
                                                      {/* Role */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )['h3'].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['h3'].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {
                                                          swipeableListSentData
                                                            ?._user_recipient
                                                            ?._os_externalrole
                                                            ?.external_role
                                                        }
                                                      </Text>
                                                    </View>
                                                    {/* Status */}
                                                    <View>
                                                      {/* Status */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['Text'].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {
                                                          swipeableListSentData?.status
                                                        }
                                                      </Text>
                                                    </View>
                                                    {/* LABEL Sent Date */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          flexDirection: 'row',
                                                          gap: 8,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Received Date */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['Text'].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {'Recieved Date:'}
                                                      </Text>
                                                      {/* Recieved Date */}
                                                      <View>
                                                        {/* Sent Date */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )['h3 bold'].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )['h3 bold'].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {convertTime(
                                                            swipeableListSentData?.created_at
                                                          )}
                                                        </Text>
                                                      </View>
                                                    </View>
                                                  </View>
                                                </View>
                                              </View>
                                            )}
                                          </>
                                          {/* Swipeable Item Button UnMatch */}
                                          <SwipeableItemButton
                                            closeOnPress={true}
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  (
                                                    await XanoJobzConnectApi.unmatchByRecipientPATCH(
                                                      Constants,
                                                      {
                                                        match_id:
                                                          swipeableListSentData?.id,
                                                      }
                                                    )
                                                  )?.json;
                                                  await refetchGetAllRecievedConnections();
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                            revealSwipeDirection={'left'}
                                            backgroundColor={
                                              theme.colors.background.danger
                                            }
                                            icon={'AntDesign/closecircleo'}
                                            title={'Reject'}
                                          />
                                        </SwipeableItem>
                                      );
                                    }}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                    style={StyleSheet.applyWidth(
                                      { flex: 1 },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                            </>
                          );
                        }}
                      </XanoJobzConnectApi.FetchGetAllSentConnectionsGET>
                    </View>
                  </SimpleStyleScrollView>
                </View>
              </View>
            )}
          </>
        </View>
      </View>
      <NavigationBottomBlock />
    </ScreenContainer>
  );
};

export default withTheme(JobzConnectActivityDetailScreen);
