import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  Pressable,
  ScreenContainer,
  Spacer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoUsersApi from '../apis/XanoUsersApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CommonPackages from '../custom-files/CommonPackages';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const PasswordResetScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [PLACEHOLDER_new_password, setPLACEHOLDER_new_password] =
    React.useState('Enter Your New Password');
  const [PLACEHOLDER_secret_code, setPLACEHOLDER_secret_code] = React.useState(
    'Enter Your Secret Code'
  );
  const [emailValue, setEmailValue] = React.useState('');
  const [input_new_pasword, setInput_new_pasword] = React.useState('');
  const [input_secret_token, setInput_secret_token] = React.useState('');
  const [show_code_failed, setShow_code_failed] = React.useState(false);
  const [show_code_success, setShow_code_success] = React.useState(false);
  const [show_password_request, setShow_password_request] =
    React.useState(true);
  const [show_password_reset, setShow_password_reset] = React.useState(false);
  const [show_right, setShow_right] = React.useState(false);
  const [show_wrong, setShow_wrong] = React.useState(false);
  const getStatus = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('status');
  };
  React.useEffect(() => {
    async function initRevenueCat() {
      const Purchases = CommonPackages.Purchases;
      if (Platform.OS == 'android') {
        await Purchases.configure({
          apiKey: 'goog_oMDLzgcmmvFWsxYzZvwQzWlaJvT',
        });
      } else {
        await Purchases.configure({
          apiKey: 'appl_uzJCdDEWfiJwAdJEWLVqDQAUgzl',
        });
      }
    }

    initRevenueCat();
  }, []);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setShow_password_request(true);
      setShow_password_reset(false);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={true}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endX={100}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Linear Gradient'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Linear Gradient'].style,
          dimensions.width
        )}
      >
        {/* Password Request Container */}
        <>
          {!(show_password_request === true) ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: 1,
                  justifyContent: 'center',
                  marginLeft: 30,
                  marginRight: 30,
                  paddingTop: 30,
                },
                dimensions.width
              )}
            >
              {/* Header */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                {/* Title */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['h1_inverse'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['h1_inverse'].style,
                      { fontFamily: 'Poppins_600SemiBold', fontSize: 36 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Request Password Reset'}
                </Text>
                {/* Subtitle */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['h3 inverse'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['h3 inverse'].style,
                    dimensions.width
                  )}
                >
                  {'Enter the email address associate with this account.'}
                </Text>
              </View>
              {/* Password Reqeust Form */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    alignSelf: [
                      { minWidth: Breakpoints.Desktop, value: 'center' },
                      { minWidth: Breakpoints.Laptop, value: 'center' },
                    ],
                    gap: 20,
                    marginTop: 24,
                  },
                  dimensions.width
                )}
              >
                {/* Error Message */}
                <>
                  {!Constants['error_message'] ? null : (
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.background.danger,
                          fontSize: 12,
                          marginBottom: 16,
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {Constants['error_message']}
                    </Text>
                  )}
                </>
                {/* Email Input */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newEmailInputValue => {
                    try {
                      setEmailValue(newEmailInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['input_text_white']
                    .props}
                  keyboardType={'email-address'}
                  placeholder={'Email'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['input_text_white']
                        .style,
                      { borderWidth: 1 }
                    ),
                    dimensions.width
                  )}
                  textContentType={'emailAddress'}
                  value={emailValue}
                />
                {/* Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Tablet, value: 'center' },
                        { minWidth: Breakpoints.Mobile, value: 'center' },
                      ],
                      flex: 1,
                    },
                    dimensions.width
                  )}
                >
                  {/* Send Reset Code */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          const response = (
                            await XanoUsersApi.requestForPasswordResetGET(
                              Constants,
                              { email: emailValue }
                            )
                          )?.json;
                          /* hidden 'Extract Key' action */
                          if (response?.id > 0) {
                            setShow_wrong(false);
                            setShow_right(true);
                          } else {
                            setShow_wrong(true);
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                        .style,
                      dimensions.width
                    )}
                    title={'Send Reset Code'}
                  />
                </View>
                {/* Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Tablet, value: 'center' },
                        { minWidth: Breakpoints.Mobile, value: 'stretch' },
                      ],
                      flex: 1,
                    },
                    dimensions.width
                  )}
                >
                  {/* Already have a Code */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setShow_password_request(false);
                        setShow_password_reset(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['button_inverse']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['button_inverse'].style,
                      dimensions.width
                    )}
                    title={'I already have a code'}
                  />
                </View>
              </View>
              {/* Display Messages */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 20 },
                  dimensions.width
                )}
              >
                {/* Pressable  */}
                <Pressable
                  onPress={() => {
                    try {
                      setShow_wrong(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Something went wrong */}
                  <>
                    {!(show_wrong === true) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderColor: palettes.App.White,
                            borderRadius: 50,
                            borderWidth: 1,
                            flex: 1,
                            flexDirection: 'row',
                            gap: 20,
                            justifyContent: 'center',
                            paddingBottom: 10,
                            paddingTop: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          size={24}
                          {...GlobalStyles.IconStyles(theme)['icon_secondary']
                            .props}
                          color={theme.colors.background.danger}
                          name={'AntDesign/closecircle'}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.IconStyles(theme)['icon_secondary']
                              .style,
                            dimensions.width
                          )}
                        />
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['text_inverse']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['text_inverse']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'Something went wrong, no email found!'}
                        </Text>
                      </View>
                    )}
                  </>
                </Pressable>

                <Pressable
                  onPress={() => {
                    try {
                      setShow_right(false);
                      setShow_password_reset(true);
                      setShow_password_request(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Something went right */}
                  <>
                    {!(show_right === true) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderColor: palettes.App.White,
                            borderRadius: 50,
                            borderWidth: 1,
                            flex: 1,
                            flexDirection: 'row',
                            gap: 20,
                            justifyContent: 'center',
                            paddingBottom: 10,
                            paddingTop: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          size={24}
                          {...GlobalStyles.IconStyles(theme)['icon_secondary']
                            .props}
                          color={palettes.Brand.Community_Primary}
                          name={'AntDesign/checkcircle'}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.IconStyles(theme)['icon_secondary']
                              .style,
                            dimensions.width
                          )}
                        />
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['text_inverse']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['text_inverse']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'Perfect, we have sent you a reset code.'}
                        </Text>
                      </View>
                    )}
                  </>
                </Pressable>
              </View>
            </View>
          )}
        </>
        {/* Reset Password Container */}
        <>
          {!(show_password_reset === true) ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: 1,
                  justifyContent: 'center',
                  marginLeft: 30,
                  marginRight: 30,
                  paddingTop: 30,
                },
                dimensions.width
              )}
            >
              {/* Header */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                {/* Title */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['h1_inverse'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['h1_inverse'].style,
                      { fontFamily: 'Poppins_600SemiBold', fontSize: 36 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Reset Your Password'}
                </Text>
                {/* Subtitle */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['h3 inverse'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['h3 inverse'].style,
                    dimensions.width
                  )}
                >
                  {"We found your email! Let's reset your password now."}
                </Text>
              </View>
              {/* Password Reset Form */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    alignSelf: [
                      { minWidth: Breakpoints.Desktop, value: 'center' },
                      { minWidth: Breakpoints.Laptop, value: 'center' },
                    ],
                    gap: 20,
                    marginTop: 24,
                  },
                  dimensions.width
                )}
              >
                {/* Error Message */}
                <>
                  {!Constants['error_message'] ? null : (
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.background.danger,
                          fontSize: 12,
                          marginBottom: 16,
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {Constants['error_message']}
                    </Text>
                  )}
                </>
                {/* Secret Code */}
                <View>
                  {/* INPUT Secret Token */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newINPUTSecretTokenValue => {
                      try {
                        setInput_secret_token(newINPUTSecretTokenValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['input_text_white']
                      .props}
                    placeholder={PLACEHOLDER_secret_code.toString() ?? 'Email'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextInputStyles(theme)['input_text_white']
                          .style,
                        { borderWidth: 1 }
                      ),
                      dimensions.width
                    )}
                    textContentType={'emailAddress'}
                    value={input_secret_token}
                  />
                </View>
                {/* New password */}
                <View>
                  {/* INPUT Password */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newINPUTPasswordValue => {
                      try {
                        setInput_new_pasword(newINPUTPasswordValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['input_text_white']
                      .props}
                    placeholder={PLACEHOLDER_new_password.toString() ?? 'Email'}
                    secureTextEntry={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextInputStyles(theme)['input_text_white']
                          .style,
                        { borderWidth: 1 }
                      ),
                      dimensions.width
                    )}
                    textContentType={'emailAddress'}
                    value={input_new_pasword}
                  />
                </View>
                {/* Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Tablet, value: 'center' },
                        { minWidth: Breakpoints.Mobile, value: 'stretch' },
                      ],
                      flex: 1,
                    },
                    dimensions.width
                  )}
                >
                  {/* Confirm */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          const passwordResponse = (
                            await XanoUsersApi.resetPasswordPOST(Constants, {
                              password: input_new_pasword,
                              secret_token: input_secret_token,
                            })
                          )?.json;
                          const password_modifiedResponse =
                            passwordResponse?.password_modified;
                          if (password_modifiedResponse === true) {
                            setShow_code_success(true);
                          } else {
                            setShow_code_failed(true);
                          }

                          navigation.navigate('LoginScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                        .style,
                      dimensions.width
                    )}
                    title={'Confirm'}
                  />
                  <Spacer bottom={8} left={8} right={8} top={8} />
                </View>
                {/* Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Tablet, value: 'center' },
                        { minWidth: Breakpoints.Mobile, value: 'stretch' },
                      ],
                      flex: 1,
                    },
                    dimensions.width
                  )}
                >
                  {/* I dont have a Code */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setShow_password_reset(false);
                        setShow_password_request(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['button_inverse']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['button_inverse'].style,
                      dimensions.width
                    )}
                    title={"I don't have a code"}
                  />
                </View>
              </View>
              {/* Display Messages */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 20 },
                  dimensions.width
                )}
              >
                {/* Pressable  */}
                <Pressable
                  onPress={() => {
                    try {
                      setShow_wrong(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Code Failed */}
                  <>
                    {!(show_wrong === true) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderColor: palettes.App.White,
                            borderRadius: 50,
                            borderWidth: 1,
                            flex: 1,
                            flexDirection: 'row',
                            gap: 20,
                            justifyContent: 'center',
                            paddingBottom: 10,
                            paddingTop: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          size={24}
                          {...GlobalStyles.IconStyles(theme)['icon_secondary']
                            .props}
                          color={theme.colors.background.danger}
                          name={'AntDesign/closecircle'}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.IconStyles(theme)['icon_secondary']
                              .style,
                            dimensions.width
                          )}
                        />
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['text_inverse']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['text_inverse']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'Toekn has expired!'}
                        </Text>
                      </View>
                    )}
                  </>
                </Pressable>

                <Pressable
                  onPress={() => {
                    try {
                      navigation.navigate('LoginScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  disabled={show_code_success}
                >
                  {/* Code Success */}
                  <>
                    {!(show_right === true) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderColor: palettes.App.White,
                            borderRadius: 50,
                            borderWidth: 1,
                            flex: 1,
                            flexDirection: 'row',
                            gap: 20,
                            justifyContent: 'center',
                            paddingBottom: 10,
                            paddingTop: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          size={24}
                          {...GlobalStyles.IconStyles(theme)['icon_secondary']
                            .props}
                          color={palettes.Brand.Community_Primary}
                          name={'AntDesign/checkcircle'}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.IconStyles(theme)['icon_secondary']
                              .style,
                            dimensions.width
                          )}
                        />
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['text_inverse']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['text_inverse']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'All done! Head over to log-in now'}
                        </Text>
                      </View>
                    )}
                  </>
                </Pressable>
              </View>
            </View>
          )}
        </>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(PasswordResetScreen);
