import React from 'react';
import {
  Button,
  Picker,
  ScreenContainer,
  Switch,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobzStoryApi from '../apis/XanoJobzStoryApi.js';
import * as XanoOptionSets$DropdownsApi from '../apis/XanoOptionSets$DropdownsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import getPickerOptions from '../global-functions/getPickerOptions';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { jobzStory_id: 14 };

const JobzStoryAddOneScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [input_salary, setInput_salary] = React.useState('');
  const [sv_displaySalary, setSv_displaySalary] = React.useState(false);
  const [sv_manageOthers, setSv_manageOthers] = React.useState(false);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={true}>
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          {/* SCREEN CONTAINER */}
          <View
            style={StyleSheet.applyWidth(
              { flex: { minWidth: Breakpoints.Desktop, value: 1 } },
              dimensions.width
            )}
          >
            {/* Heading Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Primary 4'],
                  justifyContent: 'space-around',
                },
                dimensions.width
              )}
            >
              {/* Heading  */}
              <View
                style={StyleSheet.applyWidth(
                  { margin: 20, marginBottom: 20 },
                  dimensions.width
                )}
              >
                {/* Heading */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['h2'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['h2'].style,
                      { alignSelf: 'flex-start' }
                    ),
                    dimensions.width
                  )}
                >
                  {'Keep adding to your jobzStory'}
                </Text>
                {/* description */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {"Let's add some more color to your jobzStory."}
                </Text>
              </View>
            </View>
            {/* Body Container */}
            <View>
              {/* Body */}
              <View
                style={StyleSheet.applyWidth(
                  { gap: 10, margin: 20 },
                  dimensions.width
                )}
              >
                {/* Dropdown Option Block */}
                <View
                  style={StyleSheet.applyWidth({ gap: 10 }, dimensions.width)}
                >
                  <XanoOptionSets$DropdownsApi.FetchOPTIONSETGetJobzStoryPickerOptionsGET
                    handlers={{
                      onData: fetchData => {
                        try {
                          const jobTypeResult = getPickerOptions(
                            fetchData?.dd_jobType,
                            'job_type'
                          );
                          setGlobalVariableValue({
                            key: 'JOB_TYPE_OPTIONS',
                            value: jobTypeResult,
                          });
                          const jobLevelResult = getPickerOptions(
                            fetchData?.dd_jobLevel,
                            'job_level'
                          );
                          setGlobalVariableValue({
                            key: 'JOB_LEVEL_OPTIONS',
                            value: jobLevelResult,
                          });
                          const industryResult = getPickerOptions(
                            fetchData?.dd_industry,
                            'industry'
                          );
                          setGlobalVariableValue({
                            key: 'INDUSTY_OPTIONS',
                            value: industryResult,
                          });
                          const noManagedResult = getPickerOptions(
                            fetchData?.dd_noPeopleManaged,
                            'no_of_people_managed'
                          );
                          setGlobalVariableValue({
                            key: 'NO_MANAGED_OPTIONS',
                            value: noManagedResult,
                          });
                          const teamSizeResult = getPickerOptions(
                            fetchData?.dd_teamSize,
                            'team_size'
                          );
                          setGlobalVariableValue({
                            key: 'TEAM_SIZE_OPTIONS',
                            value: teamSizeResult,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      },
                    }}
                  >
                    {({
                      loading,
                      error,
                      data,
                      refetchOPTIONSETGetJobzStoryPickerOptions,
                    }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <>
                          {/* Job Type */}
                          <View>
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['h3 bold']
                                .props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['h3 bold']
                                    .style,
                                  { marginBottom: 4 }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Job Type'}
                            </Text>
                            <Picker
                              autoDismissKeyboard={true}
                              dropDownBackgroundColor={
                                theme.colors.background.brand
                              }
                              dropDownBorderColor={theme.colors.border.brand}
                              dropDownBorderRadius={8}
                              dropDownBorderWidth={1}
                              dropDownTextColor={theme.colors.text.strong}
                              iconSize={24}
                              leftIconMode={'inset'}
                              onValueChange={newPickerValue => {
                                try {
                                  const jobTypeResult = setGlobalVariableValue({
                                    key: 'PV_JOB_TYPE',
                                    value: newPickerValue,
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              selectedIconColor={theme.colors.text.strong}
                              selectedIconName={'Feather/check'}
                              selectedIconSize={20}
                              type={'solid'}
                              {...GlobalStyles.PickerStyles(theme)['picker']
                                .props}
                              mode={'dropdown-modal'}
                              options={Constants['JOB_TYPE_OPTIONS']}
                              placeholder={'Please choose'}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.PickerStyles(theme)['picker']
                                  .style,
                                dimensions.width
                              )}
                              value={Constants['PV_JOB_TYPE']}
                            />
                          </View>
                          {/* Job Level */}
                          <View
                            style={StyleSheet.applyWidth(
                              { minWidth: '100%' },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['h3 bold']
                                .props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['h3 bold']
                                    .style,
                                  { marginBottom: 4 }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Job Level\n'}
                            </Text>
                            <Picker
                              autoDismissKeyboard={true}
                              dropDownBackgroundColor={
                                theme.colors.background.brand
                              }
                              dropDownBorderColor={theme.colors.border.brand}
                              dropDownBorderRadius={8}
                              dropDownBorderWidth={1}
                              dropDownTextColor={theme.colors.text.strong}
                              iconSize={24}
                              leftIconMode={'inset'}
                              onValueChange={newPickerValue => {
                                try {
                                  setGlobalVariableValue({
                                    key: 'PV_JOB_LEVEL',
                                    value: newPickerValue,
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              selectedIconColor={theme.colors.text.strong}
                              selectedIconName={'Feather/check'}
                              selectedIconSize={20}
                              type={'solid'}
                              {...GlobalStyles.PickerStyles(theme)['picker']
                                .props}
                              mode={'dropdown-modal'}
                              options={Constants['JOB_LEVEL_OPTIONS']}
                              placeholder={'Please choose'}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.PickerStyles(theme)['picker']
                                  .style,
                                dimensions.width
                              )}
                              value={Constants['PV_JOB_LEVEL']}
                            />
                          </View>
                          {/* Industry */}
                          <View
                            style={StyleSheet.applyWidth(
                              { minWidth: '100%', zIndex: 99 },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['h3 bold']
                                .props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['h3 bold']
                                    .style,
                                  { marginBottom: 4 }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Industry'}
                            </Text>
                            <Picker
                              autoDismissKeyboard={true}
                              dropDownBackgroundColor={
                                theme.colors.background.brand
                              }
                              dropDownBorderColor={theme.colors.border.brand}
                              dropDownBorderRadius={8}
                              dropDownBorderWidth={1}
                              iconSize={24}
                              leftIconMode={'inset'}
                              onValueChange={newPickerValue => {
                                try {
                                  setGlobalVariableValue({
                                    key: 'PV_INDUSTRY',
                                    value: newPickerValue,
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              selectedIconColor={theme.colors.text.strong}
                              selectedIconName={'Feather/check'}
                              selectedIconSize={20}
                              type={'solid'}
                              {...GlobalStyles.PickerStyles(theme)['picker']
                                .props}
                              dropDownTextColor={theme.colors.branding.primary}
                              mode={'dropdown-modal'}
                              options={Constants['INDUSTY_OPTIONS']}
                              placeholder={'Please choose'}
                              placeholderTextColor={
                                theme.colors.branding.primary
                              }
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.PickerStyles(theme)['picker']
                                    .style,
                                  { color: theme.colors.text.light }
                                ),
                                dimensions.width
                              )}
                              value={Constants['PV_INDUSTRY']}
                            />
                          </View>
                          {/* Manage Others */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['h3 bold']
                                .props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['h3 bold'].style,
                                dimensions.width
                              )}
                            >
                              {'Do you manage others?'}
                            </Text>
                            {/* Present Role */}
                            <Switch
                              onValueChange={newPresentRoleValue => {
                                try {
                                  setSv_manageOthers(newPresentRoleValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              value={sv_manageOthers}
                            />
                          </View>
                          {/* No of People Managed */}
                          <>
                            {!sv_manageOthers ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  { minWidth: '100%', zIndex: 99 },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['h3 bold']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['h3 bold']
                                        .style,
                                      { marginBottom: 4 }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'No of People Managed'}
                                </Text>
                                <Picker
                                  autoDismissKeyboard={true}
                                  dropDownBackgroundColor={
                                    theme.colors.background.brand
                                  }
                                  dropDownBorderColor={
                                    theme.colors.border.brand
                                  }
                                  dropDownBorderRadius={8}
                                  dropDownBorderWidth={1}
                                  dropDownTextColor={theme.colors.text.strong}
                                  iconSize={24}
                                  leftIconMode={'inset'}
                                  onValueChange={newPickerValue => {
                                    try {
                                      const noPeopleManagedResult =
                                        setGlobalVariableValue({
                                          key: 'PV_NO_MANAGED_PEOPLE',
                                          value: newPickerValue,
                                        });
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  selectedIconColor={theme.colors.text.strong}
                                  selectedIconName={'Feather/check'}
                                  selectedIconSize={20}
                                  type={'solid'}
                                  {...GlobalStyles.PickerStyles(theme)['picker']
                                    .props}
                                  mode={'dropdown-modal'}
                                  options={Constants['NO_MANAGED_OPTIONS']}
                                  placeholder={'Please choose'}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.PickerStyles(theme)['picker']
                                        .style,
                                      { color: theme.colors.text.light }
                                    ),
                                    dimensions.width
                                  )}
                                  value={Constants['PV_NO_MANAGED_PEOPLE']}
                                />
                              </View>
                            )}
                          </>
                          {/* Team Size */}
                          <View
                            style={StyleSheet.applyWidth(
                              { minWidth: '100%', zIndex: 99 },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['h3 bold']
                                .props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['h3 bold']
                                    .style,
                                  { marginBottom: 4 }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Team Size'}
                            </Text>
                            <Picker
                              autoDismissKeyboard={true}
                              dropDownBackgroundColor={
                                theme.colors.background.brand
                              }
                              dropDownBorderColor={theme.colors.border.brand}
                              dropDownBorderRadius={8}
                              dropDownBorderWidth={1}
                              dropDownTextColor={theme.colors.text.strong}
                              iconSize={24}
                              leftIconMode={'inset'}
                              onValueChange={newPickerValue => {
                                try {
                                  const teamSizeResult = setGlobalVariableValue(
                                    {
                                      key: 'PV_TEAM_SIZE',
                                      value: newPickerValue,
                                    }
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              selectedIconColor={theme.colors.text.strong}
                              selectedIconName={'Feather/check'}
                              selectedIconSize={20}
                              type={'solid'}
                              {...GlobalStyles.PickerStyles(theme)['picker']
                                .props}
                              mode={'dropdown-modal'}
                              options={Constants['TEAM_SIZE_OPTIONS']}
                              placeholder={'Please choose'}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.PickerStyles(theme)['picker']
                                  .style,
                                dimensions.width
                              )}
                              value={(() => {
                                const e = Constants['PV_TEAM_SIZE'];
                                console.log(e);
                                return e;
                              })()}
                            />
                          </View>
                          {/* Display Salary */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['h3 bold']
                                .props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['h3 bold'].style,
                                dimensions.width
                              )}
                            >
                              {'Display Salary?'}
                            </Text>
                            {/* Display Salary */}
                            <Switch
                              onValueChange={newDisplaySalaryValue => {
                                try {
                                  setSv_displaySalary(newDisplaySalaryValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              value={sv_displaySalary}
                            />
                          </View>
                        </>
                      );
                    }}
                  </XanoOptionSets$DropdownsApi.FetchOPTIONSETGetJobzStoryPickerOptionsGET>
                </View>
              </View>
              {/* Salary */}
              <View
                style={StyleSheet.applyWidth(
                  { marginLeft: 20, marginRight: 20 },
                  dimensions.width
                )}
              >
                {/* Heading */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['h3 bold'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['h3 bold'].style,
                    dimensions.width
                  )}
                >
                  {'What was the last salary you had in this role. '}
                </Text>
                {/* Description */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { fontSize: 12 }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'We request salary information as it helps us to match you with suitable roles, you are able to keep this private by making sure the Display Salary box isnt checked.  The default is not to display.'
                  }
                </Text>
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newTextInputValue => {
                    try {
                      setInput_salary(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter a value...'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['InputField'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['InputField'].style,
                    dimensions.width
                  )}
                  value={input_salary}
                />
              </View>
            </View>
          </View>
        </View>
        {/* Bottom Button */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              alignSelf: 'center',
              flexDirection: { minWidth: Breakpoints.Desktop, value: 'column' },
              justifyContent: {
                minWidth: Breakpoints.Desktop,
                value: 'center',
              },
              marginBottom: 20,
              marginLeft: 20,
              marginRight: 20,
              marginTop: 20,
              maxWidth: 300,
              minWidth: 300,
            },
            dimensions.width
          )}
        >
          {/* Button_Next */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  const jobzstoryResult = (
                    await XanoJobzStoryApi.jOBZSTORYUpdateOnePATCH(Constants, {
                      display_salary: sv_displaySalary,
                      industry: Constants['PV_INDUSTRY'],
                      job_level: Constants['PV_JOB_LEVEL'],
                      job_type: Constants['PV_JOB_TYPE'],
                      jobzstory_id:
                        props.route?.params?.jobzStory_id ??
                        defaultProps.jobzStory_id,
                      manages_others: sv_manageOthers,
                      no_people_managed: Constants['PV_NO_MANAGED_PEOPLE'],
                      salary: input_salary,
                      team_size: Constants['PV_TEAM_SIZE'],
                    })
                  )?.json;
                  navigation.navigate('JobzStoryAddTwoScreen', {
                    jobzStory_id: jobzstoryResult,
                  });
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.branding.secondary,
                borderRadius: 100,
                fontFamily: 'Poppins_700Bold',
                maxWidth: [
                  { minWidth: Breakpoints.Laptop, value: 300 },
                  { minWidth: Breakpoints.Mobile, value: 300 },
                ],
                minWidth: [
                  { minWidth: Breakpoints.Laptop, value: 300 },
                  { minWidth: Breakpoints.Mobile, value: 300 },
                ],
              },
              dimensions.width
            )}
            title={'Next'}
          />
          {/* Button Cancel */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('JobzStorySummaryScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['button_CLEAR'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ButtonStyles(theme)['button_CLEAR'].style,
              dimensions.width
            )}
            title={'Cancel'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(JobzStoryAddOneScreen);
