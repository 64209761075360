import React from 'react';
import {
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  SimpleStyleSwipeableList,
  SwipeableItem,
  SwipeableItemButton,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobzChatApi from '../apis/XanoJobzChatApi.js';
import NavigationBottomBlock from '../components/NavigationBottomBlock';
import SidebarMenuBlock from '../components/SidebarMenuBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import convertTime from '../global-functions/convertTime';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const JobzChatScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [ID_chatroom, setID_chatroom] = React.useState(0);
  const [blocked_by_other, setBlocked_by_other] = React.useState(false);
  const [blocked_by_user, setBlocked_by_user] = React.useState(false);
  const [chatroom_recipient_id, setChatroom_recipient_id] = React.useState(0);
  const [chatroom_user_id, setChatroom_user_id] = React.useState(0);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'CONNECT',
        value: 'chat',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
    >
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          <SidebarMenuBlock />
          {/* back navigation */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
              },
              dimensions.width
            )}
          >
            {/* Back */}
            <IconButton
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              icon={'AntDesign/arrowleft'}
            />
          </View>
          {/* SCREEN CONTAINER */}
          <View
            {...GlobalStyles.ViewStyles(theme)['screen CONTAINER'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['screen CONTAINER'].style,
              dimensions.width
            )}
          >
            {/* Heading */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginLeft: 20,
                  marginRight: 20,
                },
                dimensions.width
              )}
            >
              {/* jobzChat */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['h2'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['h2'].style,
                  dimensions.width
                )}
              >
                {'jobzChat  - All'}
              </Text>
            </View>
            {/* Description */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'column',
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 20,
                },
                dimensions.width
              )}
            >
              {/* All Matches */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { marginBottom: 10, marginRight: 20 }
                  ),
                  dimensions.width
                )}
              >
                {'These are the chats with people matched by both parties. '}
              </Text>
            </View>
            {/* Connection Container */}
            <View
              style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
            >
              <SimpleStyleScrollView
                bounces={true}
                horizontal={false}
                keyboardShouldPersistTaps={'never'}
                nestedScrollEnabled={false}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
                style={StyleSheet.applyWidth(
                  { marginLeft: 10, maxHeight: 590, minHeight: 590 },
                  dimensions.width
                )}
              >
                {/* ALL CHATS */}
                <View>
                  <XanoJobzChatApi.FetchGetAllChatsForUserSortedByFirstNameGET
                    handlers={{
                      onData: fetchData => {
                        try {
                          console.log(ID_chatroom);
                        } catch (err) {
                          console.error(err);
                        }
                      },
                    }}
                  >
                    {({
                      loading,
                      error,
                      data,
                      refetchGetAllChatsForUserSortedByFirstName,
                    }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <>
                          {/* Swipeable List  */}
                          <SimpleStyleSwipeableList
                            data={fetchData?.chatResult}
                            disableScrollWhenSwiping={true}
                            estimatedItemSize={50}
                            horizontal={false}
                            inverted={false}
                            keyExtractor={(swipeableListData, index) =>
                              swipeableListData?.id ??
                              swipeableListData?.uuid ??
                              index?.toString() ??
                              JSON.stringify(swipeableListData)
                            }
                            keyboardShouldPersistTaps={'never'}
                            listComponent={'FlatList'}
                            listKey={'XYUoZNIV'}
                            nestedScrollEnabled={false}
                            numColumns={1}
                            onEndReachedThreshold={0.5}
                            renderItem={({ item, index }) => {
                              const swipeableListData = item;
                              return (
                                <SwipeableItem
                                  closeOnPress={true}
                                  friction={20}
                                  swipeActivationPercentage={80}
                                  swipeToClosePercent={50}
                                  swipeToOpenPercent={50}
                                  {...GlobalStyles.SwipeableItemStyles(theme)[
                                    'Swipeable Item'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.SwipeableItemStyles(theme)[
                                      'Swipeable Item'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {/* Swipeable Item Button Chat */}
                                  <SwipeableItemButton
                                    closeOnPress={true}
                                    onPress={() => {
                                      try {
                                        const valueLznsj8st =
                                          swipeableListData?.chatroom_id?.id;
                                        setID_chatroom(valueLznsj8st);
                                        const chatroomIDResult = valueLznsj8st;
                                        navigation.navigate(
                                          'JobzChatChatsScreen',
                                          { ID_chatroom: chatroomIDResult }
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    revealSwipeDirection={'left'}
                                    backgroundColor={
                                      theme.colors.branding.secondary
                                    }
                                    icon={
                                      'Ionicons/chatbubble-ellipses-outline'
                                    }
                                    title={'Chat'}
                                  />
                                  {/* CHAT LIST CONTAINER */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'flex-start',
                                        flex: 1,
                                        flexDirection: 'row',
                                        marginBottom: 10,
                                        marginLeft: 20,
                                        marginRight: 20,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Left Column */}
                                    <View>
                                      <Pressable
                                        onPress={() => {
                                          try {
                                            if (
                                              swipeableListData?.primary_role ===
                                              1
                                            ) {
                                              navigation.navigate(
                                                'JobzConnectJobseekerUserProfileScreen',
                                                {
                                                  ID_selectedUser:
                                                    swipeableListData?.id,
                                                }
                                              );
                                            } else {
                                              navigation.navigate(
                                                'JobzConnectRecruiterUserProfileScreen',
                                                {
                                                  ID_selectedUser:
                                                    swipeableListData?.id,
                                                }
                                              );
                                            }
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        <Image
                                          resizeMode={'cover'}
                                          {...GlobalStyles.ImageStyles(theme)[
                                            'Image'
                                          ].props}
                                          source={imageSource(
                                            `${swipeableListData?._image_of_user?.image?.url}`
                                          )}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.ImageStyles(theme)[
                                              'Image'
                                            ].style,
                                            dimensions.width
                                          )}
                                        />
                                      </Pressable>
                                    </View>
                                    {/* Right Column */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          flex: 1,
                                          flexDirection: 'column',
                                          paddingLeft: 20,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Name */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flexDirection: 'row' },
                                          dimensions.width
                                        )}
                                      >
                                        {/* First Name */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'h2'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'h2'
                                              ].style,
                                              { marginRight: 4 }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {swipeableListData?.first_name}
                                        </Text>
                                        {/* Last Name */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'h2'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)['h2']
                                              .style,
                                            dimensions.width
                                          )}
                                        >
                                          {swipeableListData?.last_name}
                                        </Text>
                                      </View>
                                      {/* Demo User */}
                                      <View>
                                        {/* Demo */}
                                        <>
                                          {!swipeableListData?.demo ? null : (
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Text'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'DEMO USER'}
                                            </Text>
                                          )}
                                        </>
                                      </View>
                                      {/* Role */}
                                      <View>
                                        {/* Role */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'h3'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)['h3']
                                              .style,
                                            dimensions.width
                                          )}
                                        >
                                          {
                                            swipeableListData?._os_externalrole
                                              ?.external_role
                                          }
                                        </Text>
                                      </View>
                                      {/* LABEL Connected Date */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flexDirection: 'row', gap: 8 },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Connected Date */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'First Connected:'}
                                        </Text>
                                        {/* Connected Date */}
                                        <View>
                                          {/* Date */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'h3 bold'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'h3 bold'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {convertTime(
                                              swipeableListData?.chatroom_id
                                                ?.created_at
                                            )}
                                          </Text>
                                        </View>
                                      </View>
                                      {/* BLOCKED */}
                                      <View>
                                        {/* Blocked by user */}
                                        <>
                                          {!(
                                            swipeableListData?.chatroom_id
                                              ?.blocked_by_initator_user ===
                                            true
                                          ) ? null : (
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Text'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'BLOCKED'}
                                            </Text>
                                          )}
                                        </>
                                        {/* Blocked by other */}
                                        <>
                                          {!(
                                            swipeableListData?.chatroom_id
                                              ?.blocked_by_recipient === true
                                          ) ? null : (
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Text'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'BLOCKED'}
                                            </Text>
                                          )}
                                        </>
                                      </View>
                                    </View>
                                  </View>
                                </SwipeableItem>
                              );
                            }}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          />
                        </>
                      );
                    }}
                  </XanoJobzChatApi.FetchGetAllChatsForUserSortedByFirstNameGET>
                </View>
              </SimpleStyleScrollView>
            </View>
          </View>
        </View>
      </View>
      <NavigationBottomBlock />
    </ScreenContainer>
  );
};

export default withTheme(JobzChatScreen);
