import React from 'react';
import {
  Button,
  Checkbox,
  Icon,
  IconButton,
  Link,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleKeyboardAwareScrollView,
  Spacer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobzZoneApi from '../apis/XanoJobzZoneApi.js';
import NavigationBottomBlock from '../components/NavigationBottomBlock';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const JobzZoneScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [checkboxFavorite, setCheckboxFavorite] = React.useState(false);
  const [checkboxFilter, setCheckboxFilter] = React.useState(false);
  const [labelSearchInput, setLabelSearchInput] =
    React.useState('Enter Job Title');
  const [searchValue, setSearchValue] = React.useState('');
  const [checkboxValue, setCheckboxValue] = React.useState(false);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
    >
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          {/* Heading */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginBottom: 20,
                marginLeft: 20,
                marginRight: 20,
                marginTop: 20,
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['h2'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['h2'].style,
                dimensions.width
              )}
            >
              {'JobzZone'}
            </Text>
          </View>
          {/* SCREEN CONTAINER */}
          <View
            style={StyleSheet.applyWidth(
              {
                flex: [
                  { minWidth: Breakpoints.Tablet, value: 1 },
                  { minWidth: Breakpoints.Mobile, value: 1 },
                ],
                justifyContent: {
                  minWidth: Breakpoints.Tablet,
                  value: 'space-between',
                },
              },
              dimensions.width
            )}
          >
            {/* Inner Container */}
            <View>
              {/* Header */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                {/* left column */}
                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['h4'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['h4'].style,
                      dimensions.width
                    )}
                  >
                    {'Start the journey  . . . '}
                  </Text>
                </View>
                <Spacer bottom={8} left={8} right={8} top={8} />
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      gap: 8,
                      paddingBottom: 8,
                    },
                    dimensions.width
                  )}
                ></View>
              </View>
              {/* Search */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingBottom: 20, paddingLeft: 20, paddingRight: 20 },
                  dimensions.width
                )}
              >
                {/* Search Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderColor: theme.colors.border.brand,
                      borderRadius: 100,
                      borderWidth: 1,
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      paddingLeft: 10,
                    },
                    dimensions.width
                  )}
                >
                  <View>
                    <Icon
                      size={24}
                      color={theme.colors.branding.primary}
                      name={'Feather/search'}
                    />
                  </View>
                  <Spacer bottom={8} left={8} right={8} top={8} />
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'flex-start',
                        flex: 1,
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Search */}
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newSearchValue => {
                        try {
                          setSearchValue(newSearchValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)['Text Input']
                        .props}
                      placeholder={labelSearchInput.toString()}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextInputStyles(theme)['Text Input']
                            .style,
                          {
                            borderBottomWidth: null,
                            borderLeftWidth: null,
                            borderRadius: null,
                            borderRightWidth: null,
                            borderTopWidth: null,
                            minWidth: 300,
                            paddingLeft: null,
                            paddingRight: null,
                          }
                        ),
                        dimensions.width
                      )}
                      value={searchValue}
                    />
                  </View>
                </View>
              </View>

              <SimpleStyleKeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                keyboardShouldPersistTaps={'never'}
                showsVerticalScrollIndicator={true}
                viewIsInsideTabBar={false}
                style={StyleSheet.applyWidth(
                  {
                    flex: 1,
                    justifyContent: 'flex-end',
                    maxHeight: [
                      { minWidth: Breakpoints.Desktop, value: 1100 },
                      { minWidth: Breakpoints.Tablet, value: 900 },
                      { minWidth: Breakpoints.Laptop, value: 1000 },
                      { minWidth: Breakpoints.BigScreen, value: 1400 },
                      { minWidth: Breakpoints.Mobile, value: 620 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: dimensions.height <= 800,
                      },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: dimensions.height > 1000,
                      },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Result Container */}
                <View>
                  {/* Search Results */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: 20, paddingRight: 10, paddingTop: 20 },
                      dimensions.width
                    )}
                  >
                    {/* Label */}
                    <View>
                      {/* Results */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['h2'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['h2'].style,
                            { margin: 0 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Results'}
                      </Text>

                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          dimensions.width
                        )}
                      >
                        {
                          'Please note these results come from a feed and we are unable to guarantee whether these jobs are still available or even real. We are in the process of creating our own new jobzPost where we will control the validity of the information.'
                        }
                      </Text>
                    </View>
                    {/* Results Container */}
                    <View>
                      <XanoJobzZoneApi.FetchJOBZZONEGetAllJobsGET
                        search={searchValue}
                      >
                        {({
                          loading,
                          error,
                          data,
                          refetchJOBZZONEGetAllJobs,
                        }) => {
                          const fetchData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <SimpleStyleFlatList
                              data={fetchData}
                              horizontal={false}
                              inverted={false}
                              keyExtractor={(listData, index) =>
                                listData?.id ??
                                listData?.uuid ??
                                index?.toString() ??
                                JSON.stringify(listData)
                              }
                              keyboardShouldPersistTaps={'never'}
                              listKey={'dVmC5Cbb'}
                              nestedScrollEnabled={false}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const listData = item;
                                return (
                                  <>
                                    <Spacer
                                      bottom={8}
                                      left={8}
                                      right={8}
                                      top={8}
                                    />
                                    <Pressable
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            'JobzZoneJobDetailScreen',
                                            { gotojobDetail: listData?.id }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                    >
                                      {/* List Container */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            borderColor:
                                              palettes.App['Primary 2'],
                                            borderRadius: 12,
                                            borderWidth: 1,
                                            flexDirection: 'row',
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            paddingRight: 8,
                                            paddingTop: 8,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Details */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              flex: 1,
                                              flexDirection: 'column',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Company */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'h2'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'h2'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.hiring_company}
                                          </Text>
                                          {/* Job Title */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'h3'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'h3'
                                                ].style,
                                                { flex: 1 }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.job_name}
                                          </Text>
                                          {/* Location */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'h4'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'h4'
                                                ].style,
                                                {
                                                  fontFamily:
                                                    'Poppins_500Medium',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.locality}
                                          </Text>
                                          <Spacer
                                            bottom={8}
                                            left={8}
                                            right={8}
                                            top={8}
                                          />
                                          {/* Chips Row */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flex: 1,
                                                flexDirection: 'row',
                                                gap: 6,
                                                justifyContent: 'space-evenly',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Chip State */}
                                            <View
                                              {...GlobalStyles.ViewStyles(
                                                theme
                                              )['chip'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'chip'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {/* State */}
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['h4'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['h4'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {listData?.state}
                                              </Text>
                                            </View>
                                            {/* Chip Role */}
                                            <View
                                              {...GlobalStyles.ViewStyles(
                                                theme
                                              )['chip'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'chip'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {/* Role Type */}
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['h4'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['h4'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {listData?.role_type}
                                              </Text>
                                            </View>
                                            {/* Chip EMply */}
                                            <View
                                              {...GlobalStyles.ViewStyles(
                                                theme
                                              )['chip'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'chip'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {/* Employment Time */}
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['h4'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['h4'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {listData?.employment_time}
                                              </Text>
                                            </View>
                                          </View>
                                        </View>
                                      </View>
                                    </Pressable>
                                  </>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                            />
                          );
                        }}
                      </XanoJobzZoneApi.FetchJOBZZONEGetAllJobsGET>
                    </View>
                  </View>
                </View>
              </SimpleStyleKeyboardAwareScrollView>
            </View>
          </View>
        </View>
        <NavigationBottomBlock />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(JobzZoneScreen);
