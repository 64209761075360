import React from 'react';
import { IconButton, ScreenContainer, withTheme } from '@draftbit/ui';
import * as WebBrowser from 'expo-web-browser';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import BackNavigationBlock from '../components/BackNavigationBlock';
import NavigationBottomBlock from '../components/NavigationBottomBlock';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const SupportTicketScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* TOTAL WIDTH CONTAINER  */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          <BackNavigationBlock />
          {/* SCREEN CONTAINER */}
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, justifyContent: 'space-between', margin: 20 },
              dimensions.width
            )}
          >
            {/* Body */}
            <View style={StyleSheet.applyWidth({ gap: 20 }, dimensions.width)}>
              {/* Heading */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['h2'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['h2'].style,
                  dimensions.width
                )}
              >
                {'Support'}
              </Text>

              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {
                  'Need some help, let us know, we are only a click away.  As these services are separate to the app, you will need to enter your email in as a way to identify you.'
                }
              </Text>
              {/* Support Ticket */}
              <View
                {...GlobalStyles.ViewStyles(theme)['support group'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['support group'].style,
                  dimensions.width
                )}
              >
                <IconButton
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await WebBrowser.openBrowserAsync(
                          'https://jobzcafe.outseta.com/support/kb'
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  size={32}
                  icon={'Foundation/ticket'}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {
                    "Send us a support ticket.  Please be aware that tickets are handled with priority given to the Cafe Conniosseur and Chef's Table members."
                  }
                </Text>
              </View>
              {/* Chat Link */}
              <View
                {...GlobalStyles.ViewStyles(theme)['support group'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['support group'].style,
                  dimensions.width
                )}
              >
                <IconButton
                  onPress={() => {
                    try {
                      navigation.navigate('JobzMaverickScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  size={32}
                  icon={'Ionicons/chatbubble-ellipses'}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {
                    "Chat directly with us when we are online (during business hours PST). Please be aware that priority is given to the Cafe Conniosseur and Chef's Table members."
                  }
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <NavigationBottomBlock />
    </ScreenContainer>
  );
};

export default withTheme(SupportTicketScreen);
