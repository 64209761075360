import React from 'react';
import {
  Button,
  Checkbox,
  IconButton,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Swiper,
  SwiperItem,
  Switch,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XANOJobzR8terApi from '../apis/XANOJobzR8terApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const JobzR8terScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [ID_company, setID_company] = React.useState(0);
  const [ID_recruiter, setID_recruiter] = React.useState(0);
  const [LABEL_recruiterEmail, setLABEL_recruiterEmail] = React.useState(
    'Enter a Company Email'
  );
  const [SV_Q1, setSV_Q1] = React.useState(false);
  const [SV_Q2, setSV_Q2] = React.useState(false);
  const [SV_Q3, setSV_Q3] = React.useState(false);
  const [SV_Q4, setSV_Q4] = React.useState(false);
  const [SV_Q5, setSV_Q5] = React.useState(false);
  const [SV_anonymous, setSV_anonymous] = React.useState(false);
  const [compnay_name, setCompnay_name] = React.useState('');
  const [first_name, setFirst_name] = React.useState('');
  const [input_recruiterEmail, setInput_recruiterEmail] = React.useState('');
  const [last_name, setLast_name] = React.useState('');
  const [q1_score, setQ1_score] = React.useState(0);
  const [q2_score, setQ2_score] = React.useState(0);
  const [q3_score, setQ3_score] = React.useState(0);
  const [q4_score, setQ4_score] = React.useState(0);
  const [q5_score, setQ5_score] = React.useState(0);
  const [qualityIDs, setQualityIDs] = React.useState([]);
  const [search_recruiterEmail, setSearch_recruiterEmail] = React.useState('');
  const [show_continue, setShow_continue] = React.useState(false);
  const [show_emailDoesntExist, setShow_emailDoesntExist] =
    React.useState(false);
  const [show_emailExists, setShow_emailExists] = React.useState(false);
  const [show_findRecruiter, setShow_findRecruiter] = React.useState(false);
  const [show_mainContainer, setShow_mainContainer] = React.useState(true);
  const [show_ratingContainer, setShow_ratingContainer] = React.useState(false);
  const [show_success, setShow_success] = React.useState(false);
  const removeIds = id => {
    return qualityIDs.filter(item => item != id);
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'CONNECT',
        value: 'recieved',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
    >
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
            { minWidth: '100%' }
          ),
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          {/* back navigation */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
              },
              dimensions.width
            )}
          >
            {/* Back */}
            <IconButton
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              icon={'AntDesign/arrowleft'}
            />
          </View>
          {/* SCREEN CONTAINER */}
          <View
            style={StyleSheet.applyWidth(
              {
                justifyContent: [
                  { minWidth: Breakpoints.Tablet, value: 'flex-start' },
                  { minWidth: Breakpoints.Mobile, value: 'space-between' },
                ],
              },
              dimensions.width
            )}
          >
            {/* Heading */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 20,
                  marginLeft: 20,
                  marginRight: 20,
                },
                dimensions.width
              )}
            >
              {/* jobR8ter */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['h2'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['h2'].style,
                  dimensions.width
                )}
              >
                {'jobzR8ter'}
              </Text>
            </View>
            {/* MAIN CONTAINER */}
            <>
              {!(show_mainContainer === true) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { marginLeft: 20, marginRight: 20 },
                    dimensions.width
                  )}
                >
                  {/* Description */}
                  <View>
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        dimensions.width
                      )}
                    >
                      {
                        'We ask a few questions about your interaction with the recruiter you wish to rate. By rating a recruiter, you are agreeing to the fact that you did interact personally with this recruiter and all information you provide is an accurate account of your interaction.'
                      }
                    </Text>
                  </View>
                  {/* BUTTON CONTAINER */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        paddingBottom: 10,
                        paddingTop: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Button_GET STARTED */}
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setShow_findRecruiter(true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                          .style,
                        dimensions.width
                      )}
                      title={'Get Started'}
                    />
                  </View>
                </View>
              )}
            </>
            {/* RATING CONTAINER */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'column', marginLeft: 20, marginRight: 20 },
                dimensions.width
              )}
            >
              {/* Current Recruiter Reviewed */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {'You are currently rating '}
                {first_name} {last_name}
                {',\n'}
              </Text>

              <SimpleStyleScrollView
                bounces={true}
                horizontal={false}
                keyboardShouldPersistTaps={'never'}
                nestedScrollEnabled={false}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
                style={StyleSheet.applyWidth(
                  { maxHeight: 630, minHeight: 630 },
                  dimensions.width
                )}
              >
                <Swiper
                  dotActiveColor={theme.colors.branding.primary}
                  dotColor={theme.colors.text.light}
                  dotsTouchable={true}
                  loop={false}
                  minDistanceForAction={0.2}
                  minDistanceToCapture={5}
                  timeout={0}
                  vertical={false}
                  {...GlobalStyles.SwiperStyles(theme)['Swiper'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SwiperStyles(theme)['Swiper'].style,
                      { height: 530, paddingBottom: 20 }
                    ),
                    dimensions.width
                  )}
                >
                  {/* Swiper Item Negative Qualities */}
                  <SwiperItem
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Primary 2'],
                        borderColor: theme.colors.border.brand,
                        borderRadius: 20,
                        borderWidth: 1,
                        marginTop: 20,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Instructions */}
                    <View>
                      {/* Change your mind */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          dimensions.width
                        )}
                      >
                        {
                          'Select up to 3 negative qualities for this rating.  Should you wish to change your selection, click Change your Mind to remove a selection and replace it with another.  Only 3 will be counted towards the rating.'
                        }
                      </Text>
                    </View>

                    <XANOJobzR8terApi.FetchGetQualitiesGET>
                      {({ loading, error, data, refetchGetQualities }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            <SimpleStyleFlatList
                              data={fetchData?.dd_quality}
                              horizontal={false}
                              inverted={false}
                              keyExtractor={(listData, index) =>
                                listData?.id ??
                                listData?.uuid ??
                                index?.toString() ??
                                JSON.stringify(listData)
                              }
                              keyboardShouldPersistTaps={'never'}
                              listKey={JSON.stringify(fetchData?.dd_quality)}
                              nestedScrollEnabled={false}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const listData = item;
                                return (
                                  <>
                                    {/* QUALITY CONTAINER */}
                                    <>
                                      {listData?.positive ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                              justifyContent: 'space-between',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Quality */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.quality}
                                          </Text>
                                          <Checkbox
                                            onCheck={() => {
                                              try {
                                                if (qualityIDs?.length < 3) {
                                                  const valueC5OFYxyW =
                                                    qualityIDs.concat([
                                                      listData?.id,
                                                    ]);
                                                  setQualityIDs(valueC5OFYxyW);
                                                  const testResult =
                                                    valueC5OFYxyW;
                                                  console.log(testResult);
                                                } else {
                                                }
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            }}
                                            onUncheck={() => {
                                              try {
                                                const newIdsResult = removeIds(
                                                  listData?.id
                                                );
                                                setQualityIDs(newIdsResult);
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            }}
                                            defaultValue={false}
                                            disabled={qualityIDs?.length === 3}
                                          />
                                        </View>
                                      )}
                                    </>
                                  </>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                              style={StyleSheet.applyWidth(
                                {
                                  gap: 0,
                                  minHeight: 250,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  paddingTop: 6,
                                },
                                dimensions.width
                              )}
                            />
                            {/* Options */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignContent: 'center',
                                  alignSelf: 'center',
                                  flex: 1,
                                  flexDirection: 'row',
                                },
                                dimensions.width
                              )}
                            >
                              {/* Button Reset */}
                              <Button
                                iconPosition={'left'}
                                onPress={() => {
                                  try {
                                    setQualityIDs([]);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                {...GlobalStyles.ButtonStyles(theme)[
                                  'button_CLEAR'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.ButtonStyles(theme)[
                                    'button_CLEAR'
                                  ].style,
                                  dimensions.width
                                )}
                                title={'Change your Mind'}
                              />
                              {/* Anonymous */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {'Rate Anonymously\n'}
                                </Text>
                                {/* Checkbox_Anonyomous */}
                                <Checkbox
                                  onPress={newCheckboxAnonyomousValue => {
                                    try {
                                      setSV_anonymous(
                                        newCheckboxAnonyomousValue
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  status={SV_anonymous}
                                />
                              </View>
                            </View>
                            {/* BUTTON CONTAINER */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  alignSelf: 'center',
                                  flex: 1,
                                  flexDirection: 'row',
                                },
                                dimensions.width
                              )}
                            >
                              {/* Button Rate */}
                              <Button
                                iconPosition={'left'}
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      (
                                        await XANOJobzR8terApi.createNewJobzR8terPOST(
                                          Constants,
                                          {
                                            anonymous: SV_anonymous,
                                            company_id: ID_company,
                                            q1_response: SV_Q1,
                                            q1_score: q1_score,
                                            q2_response: SV_Q2,
                                            q2_score: q2_score,
                                            q3_response: SV_Q3,
                                            q3_score: q3_score,
                                            q4_response: SV_Q4,
                                            q4_score: q4_score,
                                            q5_response: SV_Q5,
                                            q5_score: q5_score,
                                            qualities: qualityIDs,
                                            recruier_id: ID_recruiter,
                                            user_id: null,
                                          }
                                        )
                                      )?.json;
                                      setShow_success(true);
                                      setShow_mainContainer(false);
                                      setShow_ratingContainer(false);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                {...GlobalStyles.ButtonStyles(theme)[
                                  'Button_Secondary'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ButtonStyles(theme)[
                                      'Button_Secondary'
                                    ].style,
                                    {
                                      height: 28,
                                      maxWidth: 100,
                                      minWidth: 100,
                                      paddingLeft: 16,
                                      paddingRight: 16,
                                      textAlign: 'center',
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={'Rate'}
                              />
                              {/* Button Cancel */}
                              <Button
                                iconPosition={'left'}
                                {...GlobalStyles.ButtonStyles(theme)[
                                  'button_CLEAR'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ButtonStyles(theme)[
                                      'button_CLEAR'
                                    ].style,
                                    {
                                      maxHeight: 28,
                                      paddingBottom: 0,
                                      paddingTop: 0,
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={'Cancel'}
                              />
                            </View>
                          </>
                        );
                      }}
                    </XANOJobzR8terApi.FetchGetQualitiesGET>
                  </SwiperItem>
                </Swiper>
              </SimpleStyleScrollView>
            </View>
          </View>
          {/* FIND RECRUITER */}
          <>
            {!(show_findRecruiter === true) ? null : (
              <View
                {...GlobalStyles.ViewStyles(theme)['outline_block'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['outline_block'].style,
                    { flex: null, gap: 20, marginTop: 20 }
                  ),
                  dimensions.width
                )}
              >
                {/* Heading */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignContent: 'center' },
                    dimensions.width
                  )}
                >
                  {/* Heading */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['h2'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['h2'].style,
                        { textAlign: 'center' }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Find a Recruiter'}
                  </Text>

                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { textAlign: 'center' }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'We require a company email address. Personal emails will not provide a result.'
                    }
                  </Text>
                </View>
                {/* SEARCH CONTAINER */}
                <View>
                  {/* BUTTON CONTAINER */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    {/* RecruiterEmail */}
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newRecruiterEmailValue => {
                        try {
                          const valueOnuBDhhm = newRecruiterEmailValue;
                          setSearch_recruiterEmail(valueOnuBDhhm);
                          const emailResult = valueOnuBDhhm;
                          setShow_emailExists(false);
                          setShow_emailDoesntExist(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)['Text Input']
                        .props}
                      placeholder={LABEL_recruiterEmail.toString()}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                        dimensions.width
                      )}
                      value={search_recruiterEmail}
                    />
                    {/* Icon Button Search */}
                    <IconButton
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const recruiterEmailResult = (
                              await XANOJobzR8terApi.getRecruiterGET(
                                Constants,
                                {
                                  primaryRole: '2',
                                  recruiterEmail: search_recruiterEmail,
                                }
                              )
                            )?.json;
                            console.log(recruiterEmailResult);
                            if (recruiterEmailResult?.primary_role === 2) {
                              setShow_emailExists(true);
                              setShow_continue(true);
                            } else {
                              setShow_emailDoesntExist(true);
                            }

                            console.log(first_name, last_name);
                            setFirst_name(recruiterEmailResult?.first_name);
                            setLast_name(recruiterEmailResult?.last_name);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      size={32}
                      icon={'MaterialCommunityIcons/account-search-outline'}
                    />
                  </View>
                  {/* MESSAGE Email doesnt exist */}
                  <>
                    {!(show_emailDoesntExist === true) ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          dimensions.width
                        )}
                      >
                        {"That email doesn't exist."}
                      </Text>
                    )}
                  </>
                  {/* MESSAGE Email exists */}
                  <>
                    {!(show_emailExists === true) ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          dimensions.width
                        )}
                      >
                        {'Recruiter Found'}
                      </Text>
                    )}
                  </>
                </View>
                {/* BUTTON CONTAINER */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  {/* Button Continue */}
                  <>
                    {!(show_continue === true) ? null : (
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            setShow_findRecruiter(false);
                            setShow_ratingContainer(true);
                            setShow_mainContainer(false);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                            .style,
                          dimensions.width
                        )}
                        title={'Continue'}
                      />
                    )}
                  </>
                  {/* Button Cancel */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setShow_findRecruiter(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button_Cancel'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['Button_Cancel'].style,
                      dimensions.width
                    )}
                    title={'Cancel'}
                  />
                </View>
              </View>
            )}
          </>
          {/* SUCCESS CONTAINER */}
          <>
            {!(show_success === true) ? null : (
              <View>
                {/* Heading Section */}
                <View
                  style={StyleSheet.applyWidth(
                    { margin: 20 },
                    dimensions.width
                  )}
                >
                  {/* Image Section */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', marginBottom: 10 },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(
                        Images['ElephantLookingLeftJobzMaverickFinal']
                      )}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          { marginBottom: 10 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Description */}
                  <View>
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        dimensions.width
                      )}
                    >
                      {
                        'Recruiters will have the ability to see the ratings you have created for them.  Your first name will be displayed unless you select the anonymous option.\n\nRecruiters will have the option of disputing the rating and then we will investigate the rating.\n'
                      }
                    </Text>
                  </View>
                </View>
                {/* Bottom Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: 'flex-end',
                      alignItems: 'center',
                      alignSelf: 'center',
                      flexDirection: {
                        minWidth: Breakpoints.Desktop,
                        value: 'row',
                      },
                      justifyContent: {
                        minWidth: Breakpoints.Desktop,
                        value: 'center',
                      },
                      marginLeft: 16,
                      marginRight: 16,
                      maxWidth: 300,
                      minWidth: 300,
                      position: 'relative',
                    },
                    dimensions.width
                  )}
                >
                  {/* Button_Next */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('KitchenJobseekerScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.branding.secondary,
                        borderRadius: 100,
                        fontFamily: 'Poppins_700Bold',
                        maxWidth: [
                          { minWidth: Breakpoints.Laptop, value: 300 },
                          { minWidth: Breakpoints.Mobile, value: 300 },
                        ],
                        minWidth: [
                          { minWidth: Breakpoints.Laptop, value: 300 },
                          { minWidth: Breakpoints.Mobile, value: 300 },
                        ],
                      },
                      dimensions.width
                    )}
                    title={'Go to the Kitchen'}
                  />
                </View>
              </View>
            )}
          </>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(JobzR8terScreen);
