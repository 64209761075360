import React from 'react';
import {
  Button,
  Pressable,
  ScreenContainer,
  SimpleStyleSwipeableList,
  SwipeableItem,
  SwipeableItemButton,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, RefreshControl, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobzStoryApi from '../apis/XanoJobzStoryApi.js';
import NavigationBottomBlock from '../components/NavigationBottomBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import convertTime from '../global-functions/convertTime';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const JobzStorySummaryScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [ID_education, setID_education] = React.useState(0);
  const [ID_jobzStory, setID_jobzStory] = React.useState(0);
  const [showEducationSelected, setShowEducationSelected] =
    React.useState(false);
  const [show_jobzSelected, setShow_jobzSelected] = React.useState(true);
  const [refreshingBve0rDgT, setRefreshingBve0rDgT] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'SELECTED',
        value: 'Kitchen',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { flex: 1, justifyContent: 'space-evenly' },
        dimensions.width
      )}
    >
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          {/* SCREEN CONTAINER */}
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, justifyContent: 'space-between' },
              dimensions.width
            )}
          >
            {/* VIEW jobzStory */}
            <View
              style={StyleSheet.applyWidth(
                { backgroundColor: palettes.App['Primary 4'] },
                dimensions.width
              )}
            >
              {/* Heading */}
              <View
                style={StyleSheet.applyWidth(
                  { margin: 20, marginBottom: 20 },
                  dimensions.width
                )}
              >
                {/* JobzStory Heading */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['h2'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['h2'].style,
                    dimensions.width
                  )}
                >
                  {
                    'All your back stories, where you have worked and where you have studied.'
                  }
                </Text>
              </View>
            </View>
            {/* Body */}
            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              {/* TABS */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 20, paddingBottom: 10 },
                  dimensions.width
                )}
              >
                {/* TABS Jobz Selected */}
                <>
                  {!(show_jobzSelected === true) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Left Column */}
                      <View
                        {...GlobalStyles.ViewStyles(theme)['tab selected']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ViewStyles(theme)['tab selected']
                              .style,
                            { paddingBottom: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        <Pressable
                          onPress={() => {
                            try {
                              setShow_jobzSelected(true);
                              setShowEducationSelected(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* Jobz */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['text selected']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['text selected']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'Jobz'}
                          </Text>
                        </Pressable>
                      </View>
                      {/* Right Column */}
                      <View
                        {...GlobalStyles.ViewStyles(theme)['tab unselected']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ViewStyles(theme)['tab unselected']
                              .style,
                            { marginRight: null, paddingBottom: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        <Pressable
                          onPress={() => {
                            try {
                              setShowEducationSelected(true);
                              setShow_jobzSelected(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* Education */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'text unselected'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['text unselected']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'Education'}
                          </Text>
                        </Pressable>
                      </View>
                    </View>
                  )}
                </>
                {/* TABS Education Selected */}
                <>
                  {!(showEducationSelected === true) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Left Column */}
                      <View
                        {...GlobalStyles.ViewStyles(theme)['tab unselected']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ViewStyles(theme)['tab unselected']
                              .style,
                            { alignContent: 'center', paddingBottom: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        <Pressable
                          onPress={() => {
                            try {
                              setShowEducationSelected(false);
                              setShow_jobzSelected(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* Jobz */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'text unselected'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['text unselected']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'Jobz'}
                          </Text>
                        </Pressable>
                      </View>
                      {/* Right Column */}
                      <View
                        {...GlobalStyles.ViewStyles(theme)['tab selected']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ViewStyles(theme)['tab selected']
                              .style,
                            { marginRight: null, paddingBottom: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        <Pressable
                          onPress={() => {
                            try {
                              setShowEducationSelected(true);
                              setShow_jobzSelected(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* Education */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'text unselected'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['text unselected']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'Education'}
                          </Text>
                        </Pressable>
                      </View>
                    </View>
                  )}
                </>
              </View>
              {/* JobzStory Container */}
              <>
                {!(show_jobzSelected === true) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flex: 1,
                        justifyContent: 'space-between',
                        marginBottom: 20,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <XanoJobzStoryApi.FetchGetJobzStoriesForASingleUserGET>
                      {({
                        loading,
                        error,
                        data,
                        refetchGetJobzStoriesForASingleUser,
                      }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <SimpleStyleSwipeableList
                            data={fetchData}
                            disableScrollWhenSwiping={true}
                            estimatedItemSize={50}
                            horizontal={false}
                            inverted={false}
                            keyExtractor={(swipeableListData, index) =>
                              swipeableListData?.id ??
                              swipeableListData?.uuid ??
                              index?.toString() ??
                              JSON.stringify(swipeableListData)
                            }
                            keyboardShouldPersistTaps={'never'}
                            listComponent={'FlatList'}
                            listKey={'Bve0rDgT'}
                            nestedScrollEnabled={false}
                            numColumns={1}
                            onEndReachedThreshold={0.5}
                            refreshControl={
                              <RefreshControl
                                refreshing={refreshingBve0rDgT}
                                onRefresh={() => {
                                  try {
                                    setRefreshingBve0rDgT(true);
                                    setID_jobzStory(fetchData);
                                    setRefreshingBve0rDgT(false);
                                  } catch (err) {
                                    console.error(err);
                                    setRefreshingBve0rDgT(false);
                                  }
                                }}
                              />
                            }
                            renderItem={({ item, index }) => {
                              const swipeableListData = item;
                              return (
                                <SwipeableItem
                                  closeOnPress={true}
                                  friction={20}
                                  swipeActivationPercentage={80}
                                  swipeToClosePercent={50}
                                  swipeToOpenPercent={50}
                                  {...GlobalStyles.SwipeableItemStyles(theme)[
                                    'Swipeable Item'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.SwipeableItemStyles(theme)[
                                      'Swipeable Item'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {/* JobzStory Record */}
                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'swipeableList'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ViewStyles(theme)[
                                          'swipeableList'
                                        ].style,
                                        { marginLeft: null }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {/* Company Name */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'h3 bold'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'h3 bold'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        swipeableListData?._company
                                          ?.company_name
                                      }
                                    </Text>
                                    {/* Internal Title */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {swipeableListData?.internal_title}
                                      {' (int)'}
                                    </Text>
                                    {/* external Title */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {swipeableListData?.external_title}
                                      {' (ext)'}
                                    </Text>
                                    {/* Start and End Dates */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {convertTime(
                                        swipeableListData?.start_date
                                      )}
                                      {'  • '}
                                      {convertTime(swipeableListData?.end_date)}
                                    </Text>
                                  </View>
                                  {/* Swipeable Item Button EDIT */}
                                  <SwipeableItemButton
                                    closeOnPress={true}
                                    onPress={() => {
                                      try {
                                        navigation.navigate(
                                          'JobzStoryUpdateExisitngScreen',
                                          {
                                            ID_jobzStory: swipeableListData?.id,
                                          }
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    revealSwipeDirection={'left'}
                                    {...GlobalStyles.SwipeableItemButtonStyles(
                                      theme
                                    )['swipe_edit'].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.SwipeableItemButtonStyles(
                                        theme
                                      )['swipe_edit'].style,
                                      dimensions.width
                                    )}
                                    title={'Edit'}
                                  />
                                  {/* Swipeable Item Button DELETE */}
                                  <SwipeableItemButton
                                    closeOnPress={true}
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          (
                                            await XanoJobzStoryApi.deleteAJobzStoryDELETE(
                                              Constants,
                                              {
                                                jobzstory_id:
                                                  swipeableListData?.id,
                                              }
                                            )
                                          )?.json;
                                          await refetchGetJobzStoriesForASingleUser();
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    revealSwipeDirection={'left'}
                                    backgroundColor={
                                      theme.colors.background.danger
                                    }
                                    title={'Delete'}
                                  />
                                </SwipeableItem>
                              );
                            }}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                          />
                        );
                      }}
                    </XanoJobzStoryApi.FetchGetJobzStoriesForASingleUserGET>
                    {/* Bottom Button */}
                    <View
                      {...GlobalStyles.ViewStyles(theme)['bottomButton'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ViewStyles(theme)['bottomButton'].style,
                        dimensions.width
                      )}
                    >
                      {/* Button Add To jobzStory */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            navigation.navigate('JobzStoryAddScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                            .style,
                          dimensions.width
                        )}
                        title={'Add to your story . . . '}
                      />
                      {/* Button Cancel */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            navigation.goBack();
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['Button_Cancel']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ButtonStyles(theme)['Button_Cancel']
                            .style,
                          dimensions.width
                        )}
                        title={'Cancel'}
                      />
                    </View>
                  </View>
                )}
              </>
              {/* Education Container */}
              <>
                {!(showEducationSelected === true) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flex: 1,
                        justifyContent: 'space-between',
                        marginBottom: 20,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <XanoJobzStoryApi.FetchGetEducationHistoriesForSingleUserGET>
                      {({
                        loading,
                        error,
                        data,
                        refetchGetEducationHistoriesForSingleUser,
                      }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <SimpleStyleSwipeableList
                            data={fetchData}
                            disableScrollWhenSwiping={true}
                            estimatedItemSize={50}
                            horizontal={false}
                            inverted={false}
                            keyExtractor={(swipeableListData, index) =>
                              swipeableListData?.id ??
                              swipeableListData?.uuid ??
                              index?.toString() ??
                              JSON.stringify(swipeableListData)
                            }
                            keyboardShouldPersistTaps={'never'}
                            listComponent={'FlatList'}
                            listKey={'z8mmv4Uk'}
                            nestedScrollEnabled={false}
                            numColumns={1}
                            onEndReachedThreshold={0.5}
                            renderItem={({ item, index }) => {
                              const swipeableListData = item;
                              return (
                                <SwipeableItem
                                  closeOnPress={true}
                                  friction={20}
                                  onSwipedRight={swipedIndex => {
                                    try {
                                      setID_education(swipedIndex);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  swipeActivationPercentage={80}
                                  swipeToClosePercent={50}
                                  swipeToOpenPercent={50}
                                  {...GlobalStyles.SwipeableItemStyles(theme)[
                                    'Swipeable Item'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.SwipeableItemStyles(theme)[
                                      'Swipeable Item'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {/* Education Record */}
                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'swipeableList'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ViewStyles(theme)[
                                          'swipeableList'
                                        ].style,
                                        { marginLeft: null }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {/* Education Level */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'h3 bold'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'h3 bold'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        swipeableListData?._os_educationlevel
                                          ?.education_level
                                      }
                                    </Text>
                                    {/* Institution Name */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        swipeableListData?._institution
                                          ?.institution_name
                                      }
                                    </Text>
                                    {/* Study Title */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        swipeableListData?._study_title
                                          ?.study_title
                                      }
                                    </Text>
                                    {/* Graduation Date */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {convertTime(
                                        swipeableListData?.year_completed
                                      )}
                                    </Text>
                                  </View>
                                  {/* Swipeable Item Button EDIT */}
                                  <SwipeableItemButton
                                    closeOnPress={true}
                                    onPress={() => {
                                      try {
                                        navigation.navigate(
                                          'JobzStoryEducationUpdateExistingScreen',
                                          {
                                            ID_education: swipeableListData?.id,
                                          }
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    revealSwipeDirection={'left'}
                                    {...GlobalStyles.SwipeableItemButtonStyles(
                                      theme
                                    )['swipe_edit'].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.SwipeableItemButtonStyles(
                                        theme
                                      )['swipe_edit'].style,
                                      dimensions.width
                                    )}
                                    title={'Edit'}
                                  />
                                  {/* Swipeable Item Button DELETE */}
                                  <SwipeableItemButton
                                    closeOnPress={true}
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          (
                                            await XanoJobzStoryApi.deleteEducationHistoryDELETE(
                                              Constants,
                                              {
                                                education_history_id:
                                                  swipeableListData?.id,
                                              }
                                            )
                                          )?.json;
                                          await refetchGetEducationHistoriesForSingleUser();
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    revealSwipeDirection={'left'}
                                    backgroundColor={
                                      theme.colors.background.danger
                                    }
                                    title={'Delete'}
                                  />
                                </SwipeableItem>
                              );
                            }}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                          />
                        );
                      }}
                    </XanoJobzStoryApi.FetchGetEducationHistoriesForSingleUserGET>
                    {/* Button */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: {
                            minWidth: Breakpoints.Desktop,
                            value: 'center',
                          },
                          flex: { minWidth: Breakpoints.Desktop, value: 1 },
                          marginLeft: 18,
                          marginRight: 18,
                          paddingTop: 20,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Button Add to Education */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            navigation.navigate('JobzStoryEducationAddScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                              .style,
                            { borderRadius: 50 }
                          ),
                          dimensions.width
                        )}
                        title={'Add to your education . . . '}
                      />
                      {/* Button Cancel */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            navigation.goBack();
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['Button_Cancel']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ButtonStyles(theme)['Button_Cancel']
                            .style,
                          dimensions.width
                        )}
                        title={'Cancel'}
                      />
                    </View>
                  </View>
                )}
              </>
            </View>
            <NavigationBottomBlock />
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(JobzStorySummaryScreen);
