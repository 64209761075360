import React from 'react';
import { ScreenContainer, Touchable, withTheme } from '@draftbit/ui';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const JobzPrepScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
    >
      {/* Top Image */}
      <Image
        resizeMode={'cover'}
        source={imageSource(Images['SplashScreen'])}
        style={StyleSheet.applyWidth(
          { height: '55%', width: '100%' },
          dimensions.width
        )}
      />
      {/* Blue Round View */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: theme.colors.branding.primary,
            borderTopLeftRadius: 40,
            borderTopRightRadius: 40,
            bottom: 0,
            height: '50%',
            left: 0,
            paddingLeft: 20,
            paddingRight: 20,
            position: 'absolute',
            right: 0,
          },
          dimensions.width
        )}
      >
        {/* Title */}
        <Text
          accessible={true}
          selectable={false}
          style={StyleSheet.applyWidth(
            {
              color: theme.colors.background.brand,
              fontFamily: 'Inter_500Medium',
              fontSize: 26,
              marginTop: 60,
              textAlign: 'center',
            },
            dimensions.width
          )}
        >
          {'jobzPrep - Coming Soon'}
        </Text>
        {/* Description */}
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: palettes.App.White,
              fontSize: 12,
              marginTop: 15,
              opacity: 0.5,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {
            'We help you prepare for you virtual interviews as many initial interactions are now handled this way.  Make sure you present the best version of you.'
          }
        </Text>
        {/* Next Btn */}
        <View
          style={StyleSheet.applyWidth(
            {
              flex: 1,
              justifyContent: 'center',
              marginBottom: 40,
              marginTop: 20,
            },
            dimensions.width
          )}
        >
          <Touchable>
            <Image
              resizeMode={'cover'}
              source={imageSource(Images['Ob2Button'])}
              style={StyleSheet.applyWidth(
                { height: 88, width: 88 },
                dimensions.width
              )}
            />
          </Touchable>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(JobzPrepScreen);
